/** FIREBASE ENVIRONMENT */
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SEND_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SEND_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

/** APPLICATION ENVIRONMENT */
export const NODE_ENV = process.env.NODE_ENV
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME
export const REQUESTER_NAME = process.env.REACT_APP_REQUESTER_NAME
export const URL_BACKEND = process.env.REACT_APP_URL_BACKEND
export const URL_BACKEND_OPERATION = process.env.REACT_APP_URL_BACKEND_OPERATION
export const KEY_BACKEND_OPERATION = process.env.REACT_APP_KEY_BACKEND_OPERATION
export const TOKEN_BACKEND_OPERATION = process.env.REACT_APP_TOKEN_BACKEND_OPERATION

/**
 * CONSTANTS DEFAULT SYSTEMS
 */

 /** TYPES ATTENDANCE */
export const TYPES_ATTENDANCE = [
    { id: 1, name: '2º via certificado' },
    { id: 2, name: 'Manual do Segurado' },
    { id: 3, name: 'Condições Gerais' },
    { id: 4, name: 'Gravação da Venda'},
    { id: 5, name: 'Evidência Cancelamento'},
    { id: 6, name: 'Reincidência de Cobrança'},
    { id: 7, name: 'Cancelamento'},
    { id: 8, name: 'Estorno de Valores'},
    { id: 9, name: 'Retenção'},
    { id: 10, name: 'Assistência'},
    { id: 11, name: 'Sinistro'}
];

/** CHANNEL ATTENDANCE */
export const CHANNEL_ATTENDANCE = [
    'GOR', 'E-mail', 'Central FSVAS', 'Parceiros', 'SMS', 'Site'
]

/** PRODUCT PROTECTED ACCOUNT */
export const PRODUCT_PROTECTED_ACCOUNT = [
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_1),
        name: 'Seguro Conta Protegida - Plano 1',
        price: 'R$ 5,90'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_2),
        name: 'Seguro Conta Protegida - Plano 2',
        price: 'R$ 7,99'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_3),
        name: 'Seguro Conta Protegida - Plano 3',
        price: 'R$ 9,99'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_4),
        name: 'Seguro Conta Protegida - Plano 4',
        price: 'R$ 13,99'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_PROTECTED_ACOUNT_PLAN_5),
        name: 'Seguro Conta Protegida - Plano 5',
        price: 'R$ 17,99'
    }
]

/** PRODUCT HOME INSURANCE */
export const PRODUCT_HOME_INSURANCE = [
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_1),
        name: 'Protecao Residencial - Plano 1',
        price: 'R$ 6,90'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_2),
        name: 'Protecao Residencial - Plano 2',
        price: 'R$ 9,90'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_3),
        name: 'Protecao Residencial - Plano 3',
        price: 'R$ 14,90'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_4),
        name: 'Protecao Residencial - Plano 4',
        price: 'R$ 18,90'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_5),
        name: 'Protecao Residencial - Plano 5',
        price: 'R$ 21,90'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_6),
        name: 'Protecao Residencial - Plano 6',
        price: 'R$ 6,99'
    },
    {
        id: parseInt(process.env.REACT_APP_PRODUCT_ID_HOME_INSURANCE_PLAN_7),
        name: 'Protecao Residencial - Plano 7',
        price: 'R$ 9,99'
    }
]