import axios from 'axios'

import {
    URL_BACKEND,
    BRAND_NAME,
    REQUESTER_NAME
} from '../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Brand-Name': BRAND_NAME,
    'X-FS-Requester-Name': REQUESTER_NAME
}

/**
 * Function Get Protocol
 * @param {int} protocol 
 */
export async function getProtocol(protocol) {
    try {
        await axios.get(`${URL_BACKEND}/bff/api/v2/protocol/${protocol}`, { headers: HEADERS });
        return { status: true };
    } catch (error) {

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }
        
        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}

/**
 * Function Send Protocol
 * @param {obj} body 
 */
export async function sendProtocol(body) {
    try {
        const response = await axios.post(`${URL_BACKEND}/bff/api/v2/protocol`, body, { headers: HEADERS });
        return { status: true, protocol: response.data.number };
    } catch (error) {

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }
        
        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}

/**
 * Function Update Protocol
 * @param {int} protocol 
 */
export async function updateProtocol(protocol) {
    try {
        await axios.put(`${URL_BACKEND}/bff/api/v2/protocol/${protocol}`, { headers: HEADERS });
        return { status: true };
    } catch (error) {

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }
        
        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}