import React from 'react'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Confirmar Compra *</label>
                            <div className="col-sm-4">

                            {props.displayFormConfirmRegister &&
                                <div>
                                    <div className="alert alert-success">
                                        <p><strong>Script</strong></p>
                                        <p>- Senhor, antes de concluir a venda do seguro será necessário confirmar algumas informações: </p>
                                    </div>
                                    <div className="hr-line-dashed" />
                                    <p><strong>Confirmação dos Dados do Cliente</strong></p>
                                    <p>Cliente: <strong>{props.fullName}</strong></p>
                                    <p>CPF: <strong>{props.cpf}</strong></p>
                                    <p>Número da Linha Segurada TIM: <strong>{props.phone}</strong></p>
                                    <div className="hr-line-dashed" />
                                </div>
                            }

                                <p>Produto: <strong>{props.productName}</strong></p>
                                <p>Valor: <strong>{props.productPrice}</strong></p>
                                <div className="hr-line-dashed" />
                                <div className="alert alert-success">
                                    <p><strong>Script</strong></p>
                                    <p>- Senhor, para sua segurança, informo que essa ligação está sendo gravada.</p>
                                    <p>- O Senhor confirma seu interesse em aderir ao “{props.productName}”?</p>
                                </div>
                            </div>
                        </div>
                        <div className="hr-line-dashed" />
                        <div className="form-group">
                            <div className="col-sm-4 col-sm-offset-2">
                                <button className="btn btn-white" type="button" onClick={props.onClickButtonBackConfirmSaleProduct} style={{ marginRight: 5 }}>Voltar</button>
                                <button className="btn btn-primary" type="button" onClick={props.onClickButtonConfirmSaleProduct}>Confirmar Venda</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}