import React from 'react'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal" onSubmit={props.handleSubmit}>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Nome *</label>
                            <div className="col-sm-4">
                                <input
                                    name="name"
                                    value={props.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Nome"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Login *</label>
                            <div className="col-sm-4">
                                <input
                                    name="userName"
                                    value={props.userName}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Login"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Senha *</label>
                            <div className="col-sm-4">
                                <input
                                    name="password"
                                    value={props.password}
                                    onChange={props.handleChange}
                                    type="password"
                                    placeholder="Senha"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Perfil *</label>
                            <div className="col-sm-4">
                                <select 
                                    className="form-control"
                                    onChange={props.handleChange}
                                    name="profileId"
                                    value={props.profileId}
                                    required
                                >
                                    <option>[Selecione uma opção]</option>
                                    {props.profileList.map((response, key) => {
                                        return <option key={key} value={response.id}>{response.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="col-sm-2 control-label">IP do Usuário *</label>
                            <div className="col-sm-10">
                                <button type="button" className="btn btn-primary" onClick={props.handleClickAddInput}>
                                    <i className="fa fa-plus"></i>
                                </button>
                                <div>
                                    {props.userIP.map((c, i) =>
                                        <div className="row" key={i} style={{ marginBottom: 15 }}>
                                            <div className="col-md-4">
                                                <label>Endereço</label>
                                                <input
                                                    type="text"
                                                    placeholder="Endereço"
                                                    className="form-control"
                                                    onChange={e => props.handleChangeAddress(i, e)}
                                                    name="address"
                                                    value={c.address}
                                                    required={props.requiredAddress}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Habilitado</label>
                                                <select 
                                                    className="form-control" 
                                                    value={c.enabled} 
                                                    onChange={e => props.handleChangeEnabled(i, e)} 
                                                    required={props.requiredEnabled}
                                                >
                                                    <option value="">[Selecione uma opção]</option>
                                                    <option value="0">Não</option>
                                                    <option value="1">Sim</option>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div> */}
                        <div className="hr-line-dashed" />
                        <div className="form-group">
                            <div className="col-sm-4 col-sm-offset-2">
                                <button className="btn btn-white" type="button" onClick={() => props.onClickReturnPage()}>Voltar</button>
                                <button className="btn btn-primary" type="submit">Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}