import React from 'react'

export default () => {
    return (
        <div className="footer">
            <div className="pull-right">
                <strong>Copyright FS</strong>© 2020
            </div>
        </div>
    )
}