import React from 'react'
import { Link } from 'react-router-dom'
import { validatorPermissionsURI } from '../../../../utils'

export default (props) => {
    return (
        <>
            {validatorPermissionsURI('group') ?
                <li className={(props.page === 'group') ? 'active' : ''}>
                    <Link to="/group">
                        <i className="fa fa-group" />
                        <span className="nav-label">Grupos</span>
                    </Link>
                </li>
                : ''
            }

            {validatorPermissionsURI('subgroup') ?
                <li className={(props.page === 'subgroup') ? 'active' : ''}>
                    <Link to="/subgroup">
                        <i className="fa fa-asterisk" />
                        <span className="nav-label">Sub-Grupos</span>
                    </Link>
                </li>
                : ''
            }

            {validatorPermissionsURI('profile') ?
                <li className={(props.page === 'profile') ? 'active' : ''}>
                    <Link to="/profile">
                        <i className="fa fa-list" />
                        <span className="nav-label">Perfil</span>
                    </Link>
                </li>
                : ''
            }

            {validatorPermissionsURI('users') ?
                <li className={(props.page === 'users') ? 'active' : ''}>
                    <Link to="/users">
                        <i className="fa fa-user" />
                        <span className="nav-label">Usuários</span>
                    </Link>
                </li>
                : ''
            }
        </>
    );
}