import React, { Component } from 'react'

import {
    getListProfile,
    getListUsers,
    insertUsers,
    updateUsers,
    deleteUsers
} from '../../../services'

import {
    toastrMessage
} from '../../../utils'

import Layout from '../../../layout'
import Content from '../../../components/Content'
import Datatables from '../../../components/Datatables'
import FormUsers from '../../../components/Forms/Settings/Users'

export default class Users extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            system: 'settings',
            page: 'users',
            description: 'Configurações :: Usuários',
            title: 'Listagem dos Usuários',
            progressBar: false,
            displayList: true,
            displayForm: false,
            displayFormUpdate: false,
            statusFlashMessage: '',
            flashMessage: '',
            action: '',
            name: '',
            userName: '',
            password: '',
            profileId: '',
            address: '',
            enabled: '',
            requiredAddress: false,
            requiredPassword: false,
            columns: {
                user_id: '#',
                user_name: 'NOME',
                group_name: 'GRUPO',
                sub_group_name: 'SUBGRUPO',
                profile_name: 'PERFIL'
            },
            usersList: [],
            totalRecord: 0,
            profileList: [],
            userIP: []
        }

        this.baseState = this.state;
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleClickAddInput = this.handleClickAddInput.bind(this);
    }

    /**
     * Component Did Mount
     */
    async componentDidMount() {
        await this.getListUsers();
        await this.getListProfile();
    }

    /**
     * Component Will Unmount
     */
    async componentWillUnmount() {
       this.setState(this.baseState);
    }    

    /**
     * On Click Return Page
     */
    onClickReturnPage = () => {
        this.setState({
            displayList: true,
            displayForm: false,
            name: '',
            userName: '',
            password: '',
            profileId: '',
            address: '',
            enabled: '',
            userIP: []
        })
    }

    /**
     * Function Insert Record
     */
    onClickInsertRecord = () => {
        this.setState({
            displayList: false,
            displayForm: true,
            title: 'Inserir Registro',
            action: 'insert'
        });
    }

    /**
     * Function Edit Record
     * @param {object} record 
     */
    onClickEditRecord = (record) => {

        console.log('record', record)

        this.setState({
            displayList: false,
            displayForm: true,
            userId: record.user_id,
            profileId: record.profile_id,
            name: record.user_name,
            userName: record.user_username,
            title: 'Editar Registro',
            action: 'edit'
        });
    }

    /**
     * Function get List Users
     */
    async getListUsers(queryString = "page=1&limit=10") {

        this.setState({ progressBar: true });

        const response = await getListUsers(queryString);

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.setState({
            loader: false,
            progressBar: false,
            displayForm: false,
            displayList: true,
            usersList: response.rows,
            totalRecord: response.totalRecord
        });
    }

    /**
     * Function Table Change Handler
     * @param {object} data 
     */
    tableChangeHandler = (data) => {

        let queryString = Object.keys(data).map((key) => {

            switch (key) {
                case 'page_number':
                    return encodeURIComponent('page') + '=' + encodeURIComponent(data[key]);
                case 'page_size':
                    return encodeURIComponent('limit') + '=' + encodeURIComponent(data[key]);
                case 'sort_order':
                    return data[key] ? encodeURIComponent('sort') + '=' + encodeURIComponent(data[key].order) : '';
                default:
                    return '';
            }

        }).filter(value => !!value).join('&');
        this.getListUsers(queryString);
    }

    /**
     * Function Get List All Profile
     */
    async getListProfile(queryString = "page=1&limit=1000") {

        this.setState({ progressBar: true });

        const response = await getListProfile(queryString);

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.setState({
            progressBar: false,
            profileList: response.rows
        });
    }

    /**
     * Function Insert Users
     */
    async insertUsers() {

        const {
            name,
            userName,
            password,
            profileId,
            // userIP,
        } = this.state;

        this.setState({
            progressBar: true,
            statusFlashMessage: '',
            flashMessage: ''
        });

        const response = await insertUsers({
            name: name,
            username: userName,
            password: password,
            profile: parseInt(profileId),
            // user_ip: userIP
        });

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.getListUsers();
        toastrMessage('success', response.message);
    }

    /**
     * Function Insert Users
     */
    async updateUsers() {

        const {
            name,
            userName,
            password,
            profileId,
            userId
            // userIP,
        } = this.state;

        this.setState({ progressBar: true });

        const response = await updateUsers({
            id: userId,
            name: name,
            username: userName,
            password: password,
            profile: parseInt(profileId),
            // user_ip: userIP
        });

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.getListUsers();
        toastrMessage('success', response.message);
    }

    /**
     * Function Delete Users
     */
    async delete(record) {
        if(window.confirm('Você deseja excluir esse registro?')){

            this.setState({ progressBar: true });

            const response = await deleteUsers(record.user_id);
    
            if (!response.status) {
                this.setState({ progressBar: false });
                toastrMessage('error', response.message);
                return;
            }
    
            toastrMessage('success', response.message);
            this.getListUsers();
        }
    }   

    /**
    * Handle Change Form Send Username and Password
    * @param {event} e 
    */
    handleChangeForm(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
    * Handle Submit Form Send Username and Password
    * @param {event} e 
    */
    async handleSubmitForm(e) {
        e.preventDefault();

        const {
            action
        } = this.state;

        switch (action) {
            case 'edit':
                this.updateUsers();
                break;

            default:
                this.insertUsers();
                break;
        }
    }

    /**
     * Handle Click Add Input
     */
    handleClickAddInput() {

        const {
            userIP
        } = this.state;

        userIP.push({
            address: '',
            enabled: ''
        });

        this.setState({
            requiredAddress: true,
            requiredEnabled: true,
            userIP: userIP
        });
    }

    /**
     * Handle Change Address
     * @param {int} i 
     * @param {object} e 
     */
    handleChangeAddress = (i, e) => {

        const {
            userIP
        } = this.state;

        userIP[i].address = e.target.value;

        this.setState({
            userIP: userIP
        });
    }

    /**
     * Handle Change Enabled
     * @param {int} i 
     * @param {object} e 
     */
    handleChangeEnabled = (i, e) => {
        const {
            userIP
        } = this.state;

        userIP[i].enabled = parseInt(e.target.value);

        this.setState({
            userIP: userIP
        });
    }

    /**
     * Render Component
     */
    render() {

        const {
            system,
            page,
            description,
            title,
            progressBar,
            name,
            userName,
            password,
            profileId,
            address,
            enabled,
            // requiredAddress,
            // requiredEnabled,
            displayList,
            displayForm,
            columns,
            usersList,
            profileList,
            // userIP,
            totalRecord
        } = this.state;

        return (
            <Layout
                system={system}
                page={page}
                description={description}
                progressBar={progressBar}
            >
                <Content title={title}>
                    {displayList &&
                        <Datatables
                            permissions={page}
                            columns={columns}
                            rows={usersList}
                            loading={progressBar}
                            insertRecord={e => this.onClickInsertRecord(e)}
                            editRecord={e => this.onClickEditRecord(e)}
                            deleteRecord={e => this.delete(e)}
                            dynamic={true}
                            totalRecord={totalRecord}
                            onChange={e => this.tableChangeHandler(e)}
                        />
                    }
                    {displayForm &&
                        <FormUsers
                            name={name}
                            userName={userName}
                            password={password}
                            profileId={profileId}
                            address={address}
                            enabled={enabled}
                            profileList={profileList}
                            // userIP={userIP}
                            // requiredAddress={requiredAddress}
                            // requiredEnabled={requiredEnabled}
                            handleChange={this.handleChangeForm}
                            handleSubmit={this.handleSubmitForm}
                            handleClickAddInput={this.handleClickAddInput}
                            handleChangeAddress={this.handleChangeAddress}
                            handleChangeEnabled={this.handleChangeEnabled}
                            onClickReturnPage={this.onClickReturnPage}
                        />
                    }
                </Content>
            </Layout>
        );
    }
}