import React from 'react'
import NumberFormat from 'react-number-format'
import { maskCPF } from '../../../../utils'
import { TYPES_ATTENDANCE, CHANNEL_ATTENDANCE } from '../../../../constants'

export default (props) => {

    const typesAttendance = TYPES_ATTENDANCE;
    const channelAttendance = CHANNEL_ATTENDANCE;

    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal" onSubmit={props.handleSubmit}>
                        {props.action === 'edit' &&
                            <>
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">Protocolo *</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="protocol"
                                            value={props.protocol}
                                            onChange={props.handleChange}
                                            type="text"
                                            placeholder="Protocolo"
                                            className="form-control"
                                            disabled={props.disabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">Data do Atendimento: *</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="date"
                                            value={props.date}
                                            onChange={props.handleChange}
                                            type="text"
                                            placeholder="Data de Atendimento"
                                            className="form-control"
                                            disabled={props.disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Nome *</label>
                            <div className="col-sm-4">
                                <input
                                    name="name"
                                    value={props.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Nome do Cliente"
                                    className="form-control"
                                    disabled={props.disabled}
                                    required
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">CPF *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    type="text"
                                    name="cpf"
                                    className="form-control"
                                    value={props.cpf}
                                    onChange={props.handleChange}
                                    format={maskCPF}
                                    placeholder="CPF"
                                    maxLength={14}
                                    disabled={props.disabled}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Linha do Telefone *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    className="form-control"
                                    value={props.phone}
                                    onChange={props.handleChange}
                                    type="text"
                                    name="phone"
                                    placeholder="Telefone Fixo"
                                    format="(##) ####-####" mask="_"
                                    disabled={props.disabled}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">E-mail *</label>
                            <div className="col-sm-4">
                                <input
                                    name="email"
                                    value={props.email}
                                    onChange={props.handleChange}
                                    type="email"
                                    placeholder="E-mail"
                                    className="form-control"
                                    disabled={props.disabled}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Canal do Atendimento *</label>
                            <div className="col-sm-4">
                                <select
                                    name="channelName"
                                    value={props.channelName}
                                    onChange={props.handleChange}
                                    className="form-control"
                                    disabled={props.disabled}
                                    required
                                >
                                    <option value="">[Selecione uma opção]</option>
                                    {channelAttendance.map((response, key) => {
                                        return <option key={key} value={response} >{response}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Tipo de Atendimento *</label>
                            <div className="col-sm-4">
                                <select
                                    name="typeId"
                                    value={props.typeId}
                                    onChange={props.handleChange}
                                    className="form-control"
                                    disabled={props.disabled}
                                    required
                                >
                                    <option value="">[Selecione uma opção]</option>
                                    {typesAttendance.map((response, key) => {
                                        return <option key={key} value={response.id} >{response.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Atendimento *</label>
                            <div className="col-sm-4">
                                <textarea
                                    className="form-control"
                                    value={props.comment}
                                    onChange={props.handleChange}
                                    name="comment"
                                    rows={10}
                                    disabled={props.disabled}
                                    required
                                />
                            </div>
                        </div>

                        {props.action === 'edit' &&
                            <>
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">Responder *</label>
                                    <div className="col-sm-4">
                                        <textarea
                                            className="form-control"
                                            value={props.answer}
                                            onChange={props.handleChange}
                                            name="answer"
                                            rows={10}
                                            disabled={false}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">Status do Atendimento *</label>
                                    <div className="col-sm-4">
                                        <select
                                            name="statusId"
                                            value={props.statusId}
                                            onChange={props.handleChange}
                                            className="form-control"
                                            disabled={false}
                                            required
                                        >
                                            <option value="">[Selecione uma opção]</option>
                                            <option value="1">Manter em Aberto</option>
                                            <option value="2">Resolvido</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="hr-line-dashed" />
                        <div className="form-group">
                            <div className="col-sm-4 col-sm-offset-2">
                                <button className="btn btn-white" style={{ marginRight: 5 }} type="button" onClick={() => props.onClickReturnPage()}>Voltar</button>
                                <button className="btn btn-primary" type="submit">Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}