import React from 'react'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal" onSubmit={props.handleSubmit}>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Compra com Sucesso *</label>
                            <div className="col-sm-10">
                                <div className="alert alert-success">
                                    <h3><strong>Script</strong></h3>
                                    <p>O Sr. receberá o Certificado de seu Seguro por email, com as informações detalhadas de todas suas coberturas, inclusive com o número da sorte com o qual o sr. participará dos sorteios mensais.</p>
                                    <p>Lembrando que o Sr. estará coberto a partir do pagamento da primeira fatura que contém a 1a cobrança deste seguro.</p>
                                    <p>Para qualquer dúvida ou necessidade referente ao {props.productName}, ligue para 4002 7041 (capitais) ou 0800 570 7041 (demais regiões), opção 3.</p>
                                    <p>Posso lhe ajudar em algo mais?</p>
                                    <p>A MAPFRE SEGUROS agradece!</p>
                                    <p>Tenha um bom dia / tarde / noite!</p>
                                </div>
                            </div>
                        </div>
                        <div className="hr-line-dashed" />
                        <div className="form-group">
                            <div className="col-sm-4 col-sm-offset-2">
                                <button className="btn btn-white" type="button" onClick={(e) => props.onClickButtonBackSaleProduct(e)}>OK</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}