import React from 'react'
import NumberFormat from 'react-number-format'
import { maskCPF, maskRG } from '../../../../../utils'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal" onSubmit={props.handleSubmit}>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Nome Completo *</label>
                            <div className="col-sm-4">
                                <input
                                    name="fullName"
                                    value={props.fullName}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Nome"
                                    className="form-control"
                                    autoFocus={true}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Data de Nascimento *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    className="form-control"
                                    value={props.birthDate}
                                    onChange={props.handleChange}
                                    type="text"
                                    name="birthDate"
                                    placeholder="Data de Nascimento"
                                    required
                                    format="##/##/####" mask="_"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">CPF *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    type="text"
                                    name="cpf"
                                    className="form-control"
                                    value={props.cpf}
                                    onChange={props.handleChange}
                                    format={maskCPF}
                                    placeholder="CPF"
                                    maxLength={14}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">RG *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    type="text"
                                    name="rg"
                                    className="form-control"
                                    value={props.rg}
                                    onChange={props.handleChange}
                                    format={maskRG}
                                    placeholder="RG"
                                    maxLength={12}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Número da Linha Segurada TIM *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    className="form-control"
                                    value={props.phone}
                                    onChange={props.handleChange}
                                    type="text"
                                    name="phone"
                                    placeholder="Telefone Fixo"
                                    required
                                    format="(##) ####-####" mask="_"
                                />
                                <span className="help-block m-b-none">
                                    <strong style={{ color: 'red', textDecoration: 'underline' }}>O número do telefone deste campo deve ser o número da linha do titular da conta telefônica. 
                                    Caso seja digitado o número incorreto o seguro não será cobrado e não haverá pagamento de comissão por venda.</strong>
                                </span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">E-mail *</label>
                            <div className="col-sm-4">
                                <input
                                    name="email"
                                    value={props.email}
                                    onChange={props.handleChange}
                                    type="email"
                                    placeholder="E-mail"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Sexo *</label>
                            <div className="col-sm-4">
                                <select
                                    name="gender"
                                    value={props.gender}
                                    onChange={props.handleChange}
                                    className="form-control"
                                    required
                                >
                                    <option value="">[Selecione uma opção]</option>
                                    <option value="FEMALE">Feminino</option>
                                    <option value="MALE">Masculino</option>
                                    <option value="OTHER">Outro(a)</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Estado Civil *</label>
                            <div className="col-sm-4">
                                <select
                                    name="maritalStatus"
                                    value={props.maritalStatus}
                                    onChange={props.handleChange}
                                    className="form-control"
                                    required
                                >
                                    <option value="">[Selecione uma opção]</option>
                                    <option value="SOLTEIRO">Solteiro(a)</option>
                                    <option value="CASADO">Casado(a)</option>
                                    <option value="VIUVO">Viúvo(a)</option>
                                    <option value="SEPARADO">Separado(a) judicialmente</option>
                                    <option value="DIVORCIADO">Divorciado(a)</option>
                                    <option value="DESQUITADO">Desquitado(a)</option>
                                    <option value="COMPANHEIRO">Companheiro(a)</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Celular </label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    className="form-control"
                                    value={props.msisdn}
                                    onChange={props.handleChange}
                                    type="text"
                                    name="msisdn"
                                    placeholder="Celular"
                                    format="(##) #####-####" mask="_"
                                />
                            </div>
                        </div>                        
                        <div className="form-group">
                            <label className="col-sm-2 control-label">CEP *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    className="form-control"
                                    value={props.cep}
                                    onChange={props.handleChange}
                                    type="text"
                                    name="cep"
                                    placeholder="CEP"
                                    format="#####-###" mask="_"
                                    onBlur={() => props.onBlurSearchCep(props.cep)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Endereço *</label>
                            <div className="col-sm-4">
                                <input
                                    name="address"
                                    value={props.address}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Endereço"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Número *</label>
                            <div className="col-sm-4">
                                <input
                                    name="number"
                                    value={props.number}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Número"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Bairro *</label>
                            <div className="col-sm-4">
                                <input
                                    name="neighborhood"
                                    value={props.neighborhood}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Bairro"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Cidade *</label>
                            <div className="col-sm-4">
                                <input
                                    name="city"
                                    value={props.city}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Cidade"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Estado *</label>
                            <div className="col-sm-4">
                                <input
                                    name="state"
                                    value={props.state}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Estado"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Complemento </label>
                            <div className="col-sm-4">
                                <input
                                    name="complement"
                                    value={props.complement}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Complemento"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="hr-line-dashed" />
                        <div className="form-group">
                            <div className="col-sm-4 col-sm-offset-2">
                                <button className="btn btn-white" type="button" onClick={props.onClickButtonBackSendSale} style={{ marginRight: 5 }}>Voltar</button>
                                <button className="btn btn-primary" type="submit">Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}