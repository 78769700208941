import JWT from 'jsonwebtoken'

import {
    SECRET_KEY
} from '../../constants'

/**
 * Function Create Protocol
 * @param {obj} data
 */
export function createProtocol(data) {
    return JWT.sign({
        data: {
            phone: data.phone,
            fullName: data.fullName,
            protocol: data.protocol,
            dateProtocol: data.dateProtocol
        }
    }, SECRET_KEY, { expiresIn: '24h' }, (err, protocol) => {
        if (err) {
            return false;
        }

        localStorage.setItem('protocol', protocol);
    });
}

/**
 * Function Validate Protocol
 * @param {type} type 
 */
export function validateProtocol(type) {
    const protocol = localStorage.getItem('protocol');

    return JWT.verify(protocol, SECRET_KEY, function (err, decoded) {
        if (err) {
            localStorage.removeItem('protocol');
            return false;
        }
        
        switch (type) {
            case 'phone':
                return decoded.data.phone;
            case 'fullName':
                return decoded.data.fullName;
            case 'protocol':
                return decoded.data.protocol;
            case 'dateProtocol':
                return decoded.data.dateProtocol;
            default:
                return true;
        }
    });
}

/**
 * Function Remove Protocol
 */
export function removeProtocol() {
    const token = localStorage.getItem('protocol');

    return JWT.verify(token, SECRET_KEY, function (err) {
        if (err) {
            return false;
        }
        localStorage.removeItem('protocol');
        return window.location = '/';
    });
}