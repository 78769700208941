import React from 'react'
import NumberFormat from 'react-number-format'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal" onSubmit={props.handleSubmit}>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Consultar por *</label>
                            <div className="col-sm-4">
                                <select
                                    value={props.type}
                                    name={props.type}
                                    onChange={props.handleChangeTypeSearch}
                                    className="form-control"
                                    required
                                >
                                    <option value="">[Selecione uma opção]</option>
                                    <option value="cpf">CPF</option>
                                    <option value="phone">Número da Linha Segurada TIM</option>
                                </select>
                            </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Digite o valor *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    className="form-control"
                                    value={props.search}
                                    onChange={props.handleChange}
                                    type="text"
                                    name="search"
                                    disabled={props.disabledInput}
                                    format={props.maskInput}
                                    placeholder={props.placeholderInput}
                                    required
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                            <div className="col-sm-4 col-sm-offset-2">
                                { props.page !== 'search' &&
                                    <button className="btn btn-white" type="button" onClick={props.onClickButtonBackSendSearch} style={{ marginRight: 5 }}>Voltar</button>                                
                                }
                                <button className="btn btn-primary" type="submit">Consultar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}