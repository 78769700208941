import axios from 'axios'

import {
    validateToken
} from '../../../utils'

import {
    URL_BACKEND_OPERATION
} from '../../../constants'

const HEADERS = {
    'auth-jwt': `Bearer ${validateToken('token')}`
}

/**
 * Function Get List by Group
 */
export async function getListAllGroup(){
    try {
        const response = await axios.get(`${URL_BACKEND_OPERATION}/callcenter/group/`, { headers: HEADERS });
        return { status: true, rows: response.data };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}

/**
 * Function Get List by Group
 */
export async function getListGroup(queryString){
    try {
        const response = await axios.get(`${URL_BACKEND_OPERATION}/callcenter/group/list?${queryString}`, { headers: HEADERS });
        return { status: true, rows: response.data.data, totalRecord: response.data.paginate.total };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}

/**
 * Function Insert Group
 */
export async function insertGroup(body){
    try {
        await axios.post(`${URL_BACKEND_OPERATION}/callcenter/group/`, body, { headers: HEADERS });
        return { status: true, message: 'Registro inserido com sucesso!' };
    } catch (error) { 

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}

/**
 * Function Update Group
 */
export async function updateGroup(body){
    try {
        await axios.put(`${URL_BACKEND_OPERATION}/callcenter/group/`, body, { headers: HEADERS });
        return { status: true, message: 'Registro alterado com sucesso!' };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}

/**
 * Function Delete Group
 */
export async function deleteGroup(id){
    try {
        await axios.delete(`${URL_BACKEND_OPERATION}/callcenter/group/${id}`, { headers: HEADERS });
        return { status: true, message: 'Registro removido com sucesso!' };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}