import React from 'react'
import NumberFormat from 'react-number-format'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal" onSubmit={props.handleSubmit}>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Nome *</label>
                            <div className="col-sm-4">
                                <input
                                    name="name"
                                    value={props.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Nome"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Nivel *</label>
                            <div className="col-sm-4">
                                <NumberFormat
                                    placeholder="Nivel"
                                    className="form-control"
                                    value={props.nivel}
                                    onChange={props.handleChange}
                                    type="text"
                                    name="nivel"
                                    required
                                />                                
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Identificador *</label>
                            <div className="col-sm-4">
                                <input
                                    name="identify"
                                    value={props.identify}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Identificador"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>                        
                        <div className="hr-line-dashed" />
                        <div className="form-group">
                            <div className="col-sm-4 col-sm-offset-2">
                                <button className="btn btn-white" type="button" onClick={() => props.onClickReturnPage()}>Voltar</button>
                                <button className="btn btn-primary" type="submit">Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}