import React, { Component } from 'react'
import queryString from 'query-string'
import Layout from '../../../layout'
import Content from '../../../components/Content'
import Datatables from '../../../components/Datatables'
import FormAttendance from '../../../components/Forms/Insurance/Attendance'
import FormInsuranceSendSearch from '../../../components/Forms/Insurance/Sale/SendSearch'

import {
    getListAttendance,
    getListAttendanceByPhone,
    getListAttendanceById,
    insertAttendance,
    updateAttendance,
    deleteAttendance,
    sendProtocol,
    getSearchCallcenter
} from '../../../services'

import {
    validateToken,
    validateProtocol,
    toastrMessage,
    validatorEmail,
    validatorLandline,
    validatorCPF,
} from '../../../utils'

export default class Attendance extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state = {
            system: 'insurance',
            page: 'attendance',
            description: 'Seguros :: Atendimentos',
            title: 'Atendimentos',
            loader: true,
            progressBar: false,
            displayList: true,
            displayFormSearch: false,
            displayForm: false,
            displayFormUpdate: false,
            userIdentity: '',
            callcenterUserId: '',
            searchAttendanceId: false,
            action: '',
            attendanceId: '',
            name: '',
            protocol: '',
            date: '',
            cpf: '',
            phone: '',
            email: '',
            channelName: '',
            type: '',
            comment: '',
            answer: '',
            statusId: '',
            statusName: '',
            disabled: false,
            required: false,
            search: '', 
            typeSearch: '',
            disabledInput: true, 
            maskInput: '', 
            placeholderInput: '',             
            columns: {
                id: '#',
                protocol: 'PROTOCOLO',
                name: 'NOME DO CLIENTE',
                date: 'DATA',
                typeName: 'TIPO',
                channelName: 'CANAL',
                statusAttendance: 'STATUS'
            },
            rows: [],
            totalRecord: 0
        }

        this.baseState = this.state;
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {

        this.setState({
            callcenterUserId: validateToken('callcenterUserId'),
            userIdentity: validateToken('userIdentity')
        });

        if (validateProtocol()) {
    
            let url = queryString.parse(this.props.location.search);
    
            if(url.a){
                this.getDataByPhone(validateProtocol('phone'));
                return;
            }
    
            if(url.e){
                this.getDataById(url.e);
                return;
            }

            window.location.href = '/search';
            return;

        }

        this.getData();
    }

    /**
     * Function On Blur Search Cep
     * @param {event} e 
     */
    handleChangeTypeSearch = (e) => {
        let typeInputSearch = e.target.value;
        switch (typeInputSearch) {
            case 'phone':
                this.setState({
                    disabledInput: false,
                    typeInputSearch: typeInputSearch,
                    placeholderInput: 'Número da Linha Segurada TIM',
                    maskInput: '(##) ####-####',
                    search: '',
                    typeSearch: typeInputSearch
                });
                break;

            case 'cpf':
                this.setState({
                    disabledInput: false,
                    typeInputSearch: typeInputSearch,
                    placeholderInput: 'CPF',
                    maskInput: '###.###.###-##',
                    search: '',
                    typeSearch: typeInputSearch
                });
                break;
            default:
                this.setState({
                    disabledInput: true,
                    statusFlashMessage: '',
                    flashMessage: '',
                    typeInputSearch: '',
                    placeholderInput: '',
                    maskInput: '',
                    search: '',
                    typeSearch: ''
                });
                break;
        }
    }


    /**
     * Function On Click Button Back Send Search
     */
    onClickButtonBackSendSearch = () => {
        this.setState({
            loader: false,
            disabled: false,
            progressBar: false,
            displayList: true,
            displayFormSearch: false,
            displayForm: false,
            disabledInput: true,
            action: '',
            statusFlashMessage: '',
            flashMessage: '',
            typeSearch: '',
            placeholderInput: '',
            maskInput: '',
            search: '',
            attendanceId: '',
            name: '',
            protocol: '',
            date: '',
            cpf: '',
            phone: '',
            email: '',
            channelName: '',
            typeId: '',
            type: '',
            comment: '',
            answer: '',
            statusId: '',
            statusName: '',
        });
        this.getData()
    }

    /**
     * On Click Button Return Page
     */
    onClickButtonReturnPage = () => {
        if (validateProtocol()) {
            window.location.href = '/search';
            return;
        }

        this.setState({
            loader: false,
            disabled: false,
            progressBar: false,
            displayForm: false,
            displayFormSearch: true,
            disabledInput: true,
            action: '',
            statusFlashMessage: '',
            flashMessage: '',
            typeSearch: '',
            placeholderInput: '',
            maskInput: '',
            search: '',
            attendanceId: '',
            name: '',
            protocol: '',
            date: '',
            cpf: '',
            phone: '',
            email: '',
            channelName: '',
            typeId: '',
            type: '',
            comment: '',
            answer: '',
            statusId: '',
            statusName: ''
        });
    }

    /**
     * Function Button Insert
     */
    onClickButtonInsert = () => {
        this.setState({
            displayList: false,
            displayFormSearch: true,
            title: 'Iniciar Atendimento - Consulta',
            disabled: false,
            progressBar: false,
            disabledInput: true,
            action: '',
            statusFlashMessage: '',
            flashMessage: '',
            typeSearch: '',
            placeholderInput: '',
            maskInput: '',
            search: '',
            attendanceId: '',
            name: '',
            protocol: '',
            date: '',
            cpf: '',
            phone: '',
            email: '',
            channelName: '',
            typeId: '',
            type: '',
            comment: '',
            answer: '',
            statusId: '',
            statusName: ''
        });
    }

    /**
     * Function Button Edit   
     * @param {object} record 
     */
    onClickButtonEdit = (record) => {
        this.setState({
            displayList: false,
            displayForm: true,
            attendanceId: record.id,
            protocol: record.protocol,
            date: record.date,
            name: record.name,
            cpf: record.cpf,
            phone: record.phone,
            email: record.email,
            channelName: record.channelName,
            typeId: record.typeId,
            comment: record.comment,
            statusId: record.statusId,
            statusName: record.statusName,
            disabled: true,
            title: 'Editar Registro',
            action: 'edit'
        });
    }

    /**
     * Function Handle Change Form Send Search
     * @param {event} e 
     */
    handleChangeFormSendSearch(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Function Handle Submit Form Send Search
     * @param {event} e 
     */
    async handleSubmitFormSendSearch(e) {
        e.preventDefault();

        const {
            search,
            typeSearch
        } = this.state;

        let inputTypeSearch = '';

        this.setState({ progressBar: true });

        switch (typeSearch) {
            case 'phone':
                const validator1 = validatorLandline(search);

                if (!validator1.status) {
                    toastrMessage('error', validator1.message)
                    this.setState({ progressBar: false });
                    return;
                }

                inputTypeSearch = validator1.landline;
                break;

            default:
                const validator2 = validatorCPF(search);

                if (!validator2.status) {
                    toastrMessage('error', validator2.message)
                    this.setState({ progressBar: false });
                    return;
                }

                inputTypeSearch = validator2.cpf;
                break;
        }

        const response = await getSearchCallcenter(typeSearch, parseInt(inputTypeSearch));

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        if (response.users.length === 0) {
            this.setState({
                progressBar: false,
                cpf: (typeSearch === 'cpf')? inputTypeSearch: '',
                phone: (typeSearch === 'phone')? inputTypeSearch: '',
                displayFormSearch: false,
                displayForm: true
            });
            return;
        }

        this.setState({
            progressBar: false,
            displayFormSearch: false,
            displayForm: true,
            name: response.users[0].fullName,
            email: response.users[0].email,
            cpf: response.users[0].cpf
        });
    }    

    /**
     * Function Get List
     */
    async getData(queryString ="page=1&limit=10")  {

        this.setState({ progressBar: true });

        const response = await getListAttendance(queryString);

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ loader: false, progressBar: false });
            return;
        }

        this.setState({
            loader: false,
            progressBar: false,
            displayForm: false,
            displayList: true,
            rows: response.rows,
            totalRecord: response.totalRecord
        });
    }

    /**
     * Function Get List Data By Id
     */
    async getDataById(id)  {

        this.setState({ progressBar: true, displayList: false });

        const response = await getListAttendanceById(id);

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.setState({
            progressBar: false,
            displayList: false,
            displayForm: true,
            searchAttendanceId: true,
            attendanceId: response.rows[0].id,
            protocol: response.rows[0].protocol,
            date: response.rows[0].date,
            name: response.rows[0].name,
            cpf: response.rows[0].cpf,
            phone: response.rows[0].phone,
            email: response.rows[0].email,
            channelName: response.rows[0].channelName,
            typeId: response.rows[0].typeId,
            comment: response.rows[0].comment,
            statusId: response.rows[0].statusId,
            statusName: response.rows[0].statusName,
            disabled: true,
            title: 'Editar Registro',
            action: 'edit'
        });        
    }  
    
    /**
     * Function Get List Data By Phone
     */
    async getDataByPhone(phone)  {

        this.setState({ progressBar: true, displayList: false });

        const response = await getListAttendanceByPhone('phone', phone);
        
        if (!response.status) {
            toastrMessage('error', response.message);
            this.setState({ progressBar: false });
            return;
        }
        
        if(response.rows.length === 0) {
            this.setState({
                progressBar: false,
                displayList: false,
                displayForm: true,
                searchAttendanceId: true,
                disabled: false,
                title: 'Inserir Registro',
                action: 'insert'
            }); 
            return;
        }

        this.setState({
            progressBar: false,
            displayList: false,
            displayForm: true,
            searchAttendanceId: true,
            date: response.rows[0].date,
            name: response.rows[0].name,
            cpf: response.rows[0].cpf,
            phone: response.rows[0].phone,
            email: response.rows[0].email,
            disabled: false,
            title: 'Inserir Registro',
            action: 'insert'
        });        
    }    

    /**
     * Function Table Change Handler
     * @param {object} data 
     */
    tableChangeHandler = (data) => {

        let queryString = Object.keys(data).map((key) => {
            switch (key) {
                case 'page_number':
                    return encodeURIComponent('page') + '=' + encodeURIComponent(data[key]);
                case 'page_size':
                    return encodeURIComponent('limit') + '=' + encodeURIComponent(data[key]);
                case 'sort_order':
                    return data[key] ? encodeURIComponent('sort') + '=' + encodeURIComponent(data[key].order) : '';
                case 'filter_value':
                    return data[key].length > 0 ? encodeURIComponent('protocol') + '=' + encodeURIComponent(data[key]) : ''
                default:
                    return '';
            }

        }).filter(value => !!value).join('&');
        this.getData(queryString);
    }

    /**
     * Function Insert
     */
    async insert() {

        const {
            name,
            cpf,
            phone,
            email,
            typeId,
            channelName,
            comment,
            callcenterUserId,
            searchAttendanceId,
            userIdentity
        } = this.state;

        const validator1 = validatorEmail(email);

        if (!validator1.status) {
            toastrMessage('error', validator1.message)
            this.setState({ progressBar: false });
            return;
        }

        const validator2 = validatorLandline(phone);

        if (!validator2.status) {
            toastrMessage('error', validator2.message)
            this.setState({ progressBar: false });
            return;
        }

        const validator3 = validatorCPF(cpf);

        if (!validator3.status) {
            toastrMessage('error', validator3.message)
            this.setState({ progressBar: false });
            return;
        }

        this.setState({ progressBar: true });

        const response1 = await sendProtocol({
            identify: userIdentity,
            event: "CALL_CENTER"
        });

        if (!response1.status) {
            toastrMessage('error', response1.message)
            this.setState({ progressBar: false });
            return;
        }

        const response2 = await insertAttendance({
            name: name,
            cpf: parseInt(validator3.cpf),
            phone: parseInt(`55${validator2.landline}`),
            email: validator1.email,
            attendance_type_id: parseInt(typeId),
            callcenter_user_id: callcenterUserId,
            protocol: response1.protocol,
            external_client_id: null,
            user_id: null,
            status: 1,
            comment: comment,
            channel: channelName,
        });

        if (!response2.status) {
            this.setState({ progressBar: false });
            toastrMessage('error', response2.message);
            return;
        }

        toastrMessage('success', response2.message);
        
        setTimeout(() => {
            (searchAttendanceId) ? window.location.href = '/search' : this.getData();            
        }, 1500);
    }

    /**
     * Function Update
     */
    async update() {

        const {
            attendanceId,
            callcenterUserId,
            searchAttendanceId,
            answer,
            statusId
        } = this.state;

        this.setState({ progressBar: true });

        const response = await updateAttendance({
            attendance_id: parseInt(attendanceId),
            callcenter_user_id: callcenterUserId,
            answer: answer,
            status: parseInt(statusId)
        });

        if (!response.status) {
            this.setState({ progressBar: false });
            toastrMessage('error', response.message);
            return;
        }
        
        toastrMessage('success', response.message);
        
        setTimeout(() => {
            (searchAttendanceId) ? window.location.href = '/search' : this.getData();            
        }, 1500);
    }

    /**
     * Function Delete
     */
    async delete(record) {
        if (window.confirm('Você deseja excluir esse registro?')) {

            this.setState({ progressBar: true });

            const response = await deleteAttendance(record.id);

            if (!response.status) {
                this.setState({ progressBar: false });
                toastrMessage('error', response.message);
                return;
            }

            this.getData();
            toastrMessage('success', response.message);
        }
    }

    /**
    * Handle Change Form
    * @param {event} e 
    */
    handleChangeForm(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
    * Handle Submit Form
    * @param {event} e 
    */
    async handleSubmitForm(e) {
        e.preventDefault();

        const {
            action
        } = this.state;

        switch (action) {
            case 'edit':
                this.update();
                break;

            default:
                this.insert();
                break;
        }
    }

    /**
     * Render Component
     */
    render() {

        const {
            system,
            page,
            description,
            title,
            action,
            disabled,
            required,
            protocol,
            date,
            name,
            phone,
            cpf,
            email,
            channelName,
            typeId,
            comment,
            answer,
            statusId,
            loader,
            progressBar,
            displayList,
            displayFormSearch,
            displayForm,
            columns,
            rows,
            totalRecord,
            typeInput,
            search,
            disabledInput,
            maskInput,
            placeholderInput            
        } = this.state;

        return (
            <Layout
                page={page}
                system={system}
                description={description}
                progressBar={progressBar}
            >
                <Content title={title}>
                    {displayList &&
                        <Datatables
                            permissions={page}
                            columns={columns}
                            rows={rows}
                            loading={loader}
                            filter={true}
                            insertRecord={e => this.onClickButtonInsert(e)}
                            editRecord={e => this.onClickButtonEdit(e)}
                            deleteRecord={e => this.delete(e)}
                            dynamic={true}
                            totalRecord={totalRecord}
                            onChange={e => this.tableChangeHandler(e)}
                        />
                    }
                    
                    {displayFormSearch &&
                        <FormInsuranceSendSearch
                            type={typeInput}
                            search={search}
                            disabledInput={disabledInput}
                            maskInput={maskInput}
                            placeholderInput={placeholderInput}
                            handleChangeTypeSearch={this.handleChangeTypeSearch}
                            onClickButtonBackSendSearch={e => this.onClickButtonBackSendSearch(e)}
                            handleChange={e => this.handleChangeFormSendSearch(e)}
                            handleSubmit={e => this.handleSubmitFormSendSearch(e)}
                        />
                    }

                    {displayForm &&
                        <FormAttendance
                            disabled={disabled}
                            required={required}
                            protocol={protocol}
                            date={date}
                            name={name}
                            cpf={cpf}
                            phone={phone}
                            email={email}
                            channelName={channelName}
                            typeId={typeId}
                            comment={comment}
                            action={action}
                            answer={answer}
                            statusId={statusId}
                            handleChange={e => this.handleChangeForm(e)}
                            handleSubmit={e => this.handleSubmitForm(e)}
                            onClickReturnPage={this.onClickButtonReturnPage}
                        />
                    }
                </Content>
            </Layout>
        );
    }
}