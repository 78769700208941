import axios from 'axios'
import moment from 'moment'

import {
    validateToken
} from '../../../utils'

import {
    URL_BACKEND,
    BRAND_NAME,
    REQUESTER_NAME
} from '../../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Brand-Name': BRAND_NAME,
    'X-FS-Requester-Name': REQUESTER_NAME,
    'Authorization': `Bearer ${validateToken('token')}`
}

/**
 * Function Get Search Callcenter
 * @param {string} type 
 * @param {int} value 
 */
export async function getSearchCallcenter(type, value) {

    value = (type === 'phone')? `55${value}` : value;

    try {
        const response = await axios.get(`${URL_BACKEND}/bff/api/v2/insurance/callcenter/client/search/${type}/${value}`, { headers: HEADERS });  
        let users = [];

        response.data.data.forEach((response) => {

            let bundle = [];

            response.policies.forEach((response) => {

                let payment = [];

                response.billing_order.forEach((response) => {
                    payment.push({
                        id: response.id,
                        dueDate: response.due_date,
                        paymentData: response.payment_status_return_file !== null ? response.payment_status_return_file.created_at : '',
                        processStatus: response.process_status
                    });
                });

                bundle.push({
                    id: response.id,
                    name: response.bundle.name || '',
                    value: response.bundle.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || '',
                    phone: response.phone || '',
                    createdAt: response.created_at || '',
                    statusSubscription: (response.actived === 'ACTIVE') ? 'ATIVO': 'CANCELADO',
                    validityStart: response.incio_vigencia,
                    validityEnd: response.final_vigencia,
                    luckNumber: response.luck_number.length > 0 ? response.luck_number[0].lotery_number : '',
                    subscriptionId: response.subscription_id || '',
                    insurancePolicyNumber: response.insurance_policy_number || '',
                    billingOrder: payment
                });
            });

            let cpf = response.cpf ?
                (response.cpf.toString().length < 11) ? response.cpf.toString().padStart(11, 0) : response.cpf.toString()
            : '';
                
            let phone = response.phone ?
                (response.phone.toString().length > 10) ? response.phone.toString().substr(2) : response.phone.toString()
            : '';

            let cep = response.address.cep ?
                (response.address.cep.length < 8) ? response.address.cep.padStart(8, 0) : response.address.cep.toString()
            : '';

            let birthDate = response.birth_date !== null ? moment(response.birth_date).format("DD/MM/YYYY") : '';

            users.push({
                id: response.id,
                pxUserId: response.px_user_id,
                fullName: response.fullname || '',
                cpf: cpf,
                email: response.email || '',
                birthDate: birthDate,
                msisdn: response.msisdn || '',
                phone: phone,
                rg: response.rg || '',
                gender: response.gender || '',
                maritalStatus: response.marital_status || '',
                cep: cep,
                address: response.address.description || '',
                number: response.address_number || '',
                complement: response.address_complement || '',
                neighborhood: response.address.neighborhood.description || '',
                city: response.address.neighborhood.city.description || '',
                state: response.address.neighborhood.city.uf.code || '',
                bundle: bundle
            });
        });
           
        return {
            status: true,
            users: users
        }

    } catch (error) {

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}

/**
 * Function Send Edit User Info Callcenter
 * @param {object} body 
 */
export async function sendEditUserInfoCallcenter(body){
    try {
        await axios.put(`${URL_BACKEND}/bff/api/v2/insurance/callcenter/client`, body, { headers: HEADERS });
        return { status: true, message: 'Registro alterado com sucesso!' };
    } catch (error) {

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}