import React, { Component } from 'react'
import moment from 'moment'
import Layout from '../../../layout'
import Content from '../../../components/Content'
import FlashMessage from '../../../components/FlashMessage'
import FormInsuranceSendSearch from '../../../components/Forms/Insurance/Sale/SendSearch'
import TableInsuranceSaleSearchClient from '../../../components/Tables/Insurance/Sale/SearchClient'
import Datatables from '../../../components/Datatables'
import TableInsuranceSale from '../../../components/Tables/Insurance/Sale'
import TableInsuranceHistoryClient from '../../../components/Tables/Insurance/HistoryClient'
import FormInsuranceConfirmSale from '../../../components/Forms/Insurance/Sale/ConfirmSale'
import FormInsuranceSuccessSale from '../../../components/Forms/Insurance/Sale/SuccessSale'
import FormInsuranceSendEditUserInfo from '../../../components/Forms/Insurance/Sale/SendSale'

import {
    createProtocol,
    validateProtocol,
    validateToken,
    validatorLandline,
    validatorCPF,
    toastrMessage,
    validatorPhoneNumber,
    validatorDate,
    validatorCEP,
    validatorPermissions
} from '../../../utils'

import {
    sendProtocol,
    getProtocolCallcenter,
    getListAttendanceByPhone,
    sendProtocolCallcenter,
    getSearchCallcenter,
    subscribeCallcenter,
    unSubscribeCallcenter,
    deleteAttendance,
    sendEditUserInfoCallcenter
} from '../../../services'

export default class Search extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            system: 'search',
            page: 'search',
            description: 'Seguros :: Pesquisa',
            title: 'Listagem das Pesquisas',
            progressBar: false,
            statusFlashMessage: '',
            flashMessage: '',
            displayFormSearch: true,
            displayUserInfo: true,
            displayTableSearchClient: false,
            displayNavbarTabsInsuranceSearch: false,
            displayTableInsuranceSale: false,
            displayFormConfirmSale: false,
            displayFormSuccessSale: false,
            displayNavbarTabSearchClient: false,
            displayNavbarTabSaleProduct: false,
            buttonSubscriptionProduct: true,
            displayNavbarTabAttendance: false,
            displayFormEditUserInfo: false,
            displayFormPayment: false,
            buttonHistoryProduct: true,
            buttonHistoryPayment: true,
            disabledInput: true,
            protocolStatus: false,
            partner: 'vivo fixa',
            actor: 'callcenter',
            protocol: '',
            callcenterUserId: '',
            campaignId: '',
            identifier: '',
            type: '',
            search: '',
            maskInput: '',
            typeInputSearch: '',
            placeholderInput: '',
            userId: '',
            fullName: '',
            birthDate: '',
            msisdn: '',
            phone: '',
            cpf: '',
            rg: '',
            email: '',
            gender: '',
            maritalStatus: '',
            cep: '',
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
            productName: '',
            productPrice: '',
            columnsUsers: {
                fullName: 'NOME',
                cpf: 'CPF',
                phone: 'TELEFONE',
            },
            columnsProducts: {
                id: '#',
                phone: 'LINHA SEGURADA',
                name: 'PRODUTO',
                value: 'VALOR',
                createdAt: 'DATA DA ADESÃO',
                validityStart: 'INICIO DA VIGENCIA',
                validityEnd: 'FIM DA VIGENCIA',
                luckNumber: 'NUMERO DA SORTE',
                statusSubscription: 'STATUS'
            },
            columnsProtocol: {
                id: '#',
                protocol: 'PROTOCOLO',
                create_date: 'DATA DE ABERTURA',
                dataFechamento: 'DATA DE FECHAMENTO',
                action: 'AÇÃO',
                status: 'STATUS'
            },
            columnsAttendance: {
                id: '#',
                protocol: 'PROTOCOLO',
                name: 'NOME DO CLIENTE',
                date: 'DATA',
                typeName: 'TIPO',
                channelName: 'CANAL',
                statusAttendance: 'STATUS'
            },
            columnsPayment: {
                id: '#',
                dueDate: 'DATA DE PAGAMENTO',
                paymentData: 'DATA DE VENCIMENTO',
                processStatus: 'VALOR COBRADO'
            },
            rowsProducts: [],
            rowsAttendance: [],
            rowsProtocol: [],
            rowsUsers: []
        }

        this.onClickButtonUnSubscription = this.onClickButtonUnSubscription.bind(this);
        this.onClickButtonConfirmSaleProduct = this.onClickButtonConfirmSaleProduct.bind(this);
        this.onClickButtonHistoryProduct = this.onClickButtonHistoryProduct.bind(this);
        this.onBlurSearchCep = this.onBlurSearchCep.bind(this);
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.validateProtocol();

        this.setState({
            callcenterUserId: validateToken('callcenterUserId'),
            identifier: validateToken('userIdentity')
        });
    }

    /**
     * Function Validate Protocol
     */
    async validateProtocol() {
        if (validateProtocol()) {
            this.setState({ displayFormSearch: false, progressBar: true });

            const response1 = await getSearchCallcenter('phone', validateProtocol('phone'));

            if (!response1.status) {
                toastrMessage('error', response1.message)
                this.setState({ progressBar: false });
                return;
            }

            const response2 = await getProtocolCallcenter(response1.users[0].id);

            if (!response2.status) {
                toastrMessage('error', response2.message)
                this.setState({ progressBar: false });
                return;
            }

            this.setState({
                progressBar: false,
                displayFormSearch: false,
                displayNavbarTabsInsuranceSearch: true,
                displayNavbarTabSearchClient: true,
                displayTableInsuranceSale: true,
                protocol: validateProtocol('protocol'),
                userId: response1.users[0].id,
                fullName: response1.users[0].fullName,
                birthDate: response1.users[0].birthDate,
                msisdn: response1.users[0].msisdn,
                phone: response1.users[0].phone,
                cpf: response1.users[0].cpf,
                rg: response1.users[0].rg,
                email: response1.users[0].email,
                gender: response1.users[0].gender,
                maritalStatus: response1.users[0].maritalStatus,
                cep: response1.users[0].cep,
                address: response1.users[0].address,
                number: response1.users[0].number,
                complement: response1.users[0].complement,
                neighborhood: response1.users[0].neighborhood,
                city: response1.users[0].city,
                state: response1.users[0].state,
                rowsProducts: response1.users[0].bundle,
                rowsUsers: response1.users,
                rowsAttendance: response1.attendance,
                rowsProtocol: response2.rows
            });
        }
    }

    /**
     * Function On Blur Search Cep
     * @param {event} e 
     */
    handleChangeTypeSearch = (e) => {
        let typeInputSearch = e.target.value;
        switch (typeInputSearch) {
            case 'phone':
                this.setState({
                    disabledInput: false,
                    typeInputSearch: typeInputSearch,
                    placeholderInput: 'Número da Linha Segurada TIM',
                    maskInput: '(##) # ####-####',
                    search: '',
                    type: typeInputSearch
                });
                break;

            case 'cpf':
                this.setState({
                    disabledInput: false,
                    typeInputSearch: typeInputSearch,
                    placeholderInput: 'CPF',
                    maskInput: '###.###.###-##',
                    search: '',
                    type: typeInputSearch
                });
                break;
            default:
                this.setState({
                    disabledInput: true,
                    statusFlashMessage: '',
                    flashMessage: '',
                    typeInputSearch: '',
                    placeholderInput: '',
                    maskInput: '',
                    search: '',
                    type: ''
                });
                break;
        }
    }

    /**
     * Function Handle Change Form Send Search
     * @param {event} e 
     */
    handleChangeFormSendSearch(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Function Handle Submit Form Send Search
     * @param {event} e 
     */
    async handleSubmitFormSendSearch(e) {
        e.preventDefault();

        const {
            search,
            typeInputSearch
        } = this.state;

        let searchInput = '';

        this.setState({ progressBar: true, displayTableSearchClient: false });

        switch (typeInputSearch) {
            case 'phone':
                const validator1 = validatorLandline(search);

                if (!validator1.status) {
                    toastrMessage('error', validator1.message)
                    this.setState({ progressBar: false });
                    return;
                }

                searchInput = validator1.landline;
                break;

            default:
                const validator2 = validatorCPF(search);

                if (!validator2.status) {
                    toastrMessage('error', validator2.message)
                    this.setState({ progressBar: false });
                    return;
                }

                searchInput = validator2.cpf;
                break;
        }

        const response = await getSearchCallcenter(typeInputSearch, parseInt(searchInput));

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        if (response.users.length === 0) {
            this.setState({
                progressBar: false,
                statusFlashMessage: true,
                flashMessage: 'Cliente não encontrado. Tente novamente!'
            });
            return;
        }

        this.setState({
            progressBar: false,
            statusFlashMessage: '',
            flashMessage: '',
            displayTableSearchClient: true,
            displayTableInsuranceSale: true,
            rowsUsers: response.users,
            rowsAttendance: response.attendance,
            rowsProtocol: response.protocol,
            search: search,
            type: typeInputSearch,
            typeInputSearch: typeInputSearch
        });
    }

    /**
     * Function On Click Button History Product
     * @param {event} e 
     * @param {object} record 
     * @param {string} type 
     */
    async onClickButtonHistoryProduct(e, record, type) {
        e.preventDefault();

        const {
            callcenterUserId,
            identifier
        } = this.state;

        this.setState({ progressBar: true });

        const response1 = await getProtocolCallcenter(record.pxUserId);

        if (!response1.status) {
            toastrMessage('error', response1.message)
            this.setState({ progressBar: false });
            return;
        }

        const response2 = await sendProtocol({
            identify: identifier,
            event: "CALL_CENTER"
        });

        if (!response2.status) {
            toastrMessage('error', response2.message)
            this.setState({ progressBar: false });
            return;
        }

        const response3 = await sendProtocolCallcenter({
            protocol: response2.protocol,
            user: record.id,
            callcenter_userid: callcenterUserId,
            status: 'OPEN',
            action: 'SEARCH'
        });

        if (!response3.status) {
            toastrMessage('error', response3.message)
            this.setState({ progressBar: false });
            return;
        }

        createProtocol({
            phone: `${record.phone}`,
            fullName: record.fullName,
            protocol: response2.protocol,
            dateProtocol: response3.dateProtocol
        });

        setTimeout(() => {
            this.setState({
                progressBar: false,
                displayFormSearch: false,
                displayTableSearchClient: false,
                displayNavbarTabsInsuranceSearch: true,
                displayNavbarTabSearchClient: (type === 'history') ? true : false,
                displayNavbarTabSaleProduct: (type === 'salle') ? true : false,
                protocolStatus: true,
                protocol: response2.protocol,
                dateProtocol: response3.dateProtocol,
                userId: record.id,
                fullName: record.fullName,
                birthDate: record.birthDate,
                msisdn: record.msisdn,
                phone: record.phone,
                cpf: record.cpf,
                rg: record.rg,
                email: record.email,
                gender: record.gender,
                maritalStatus: record.maritalStatus,
                cep: record.cep,
                address: record.address,
                number: record.number,
                complement: record.complement,
                neighborhood: record.neighborhood,
                city: record.city,
                state: record.state,
                rowsProducts: record.bundle,
                rowsProtocol: response1.rows
            });
        }, 1000);
    }

    /**
     * Function On Click Button UnSubscription
     * @param {object} record 
     */
    async onClickButtonUnSubscription(record) {

        const {
            partner,
            userId,
            protocol,
            callcenterUserId
        } = this.state;

        if (window.confirm("Você realmente deseja cancelar esta cobrança?")) {

            this.setState({
                progressBar: true,
                statusFlashMessage: '',
                flashMessage: ''
            });

            const response = await unSubscribeCallcenter({
                carrier_subscription_id: record.subscriptionId,
                insurance_policy_number: record.insurancePolicyNumber,
                protocol: protocol,
                callcenter_userid: callcenterUserId,
                partner: partner
            });

            if (!response.status) {
                this.setState({
                    progressBar: false,
                    statusFlashMessage: true,
                    flashMessage: response.message
                });
                return;
            }

            const response2 = await sendProtocolCallcenter({
                protocol: protocol,
                user: userId,
                callcenter_userid: callcenterUserId,
                offer: 1234,
                status: 'OPEN',
                action: 'UNSUBSCRIPTION'
            });

            if (!response2.status) {
                this.setState({
                    progressBar: false,
                    statusFlashMessage: true,
                    flashMessage: response2.message
                });
                return;
            }

            this.setState({
                progressBar: false,
                statusFlashMessage: false,
                flashMessage: 'Produto cancelado com sucesso!'
            });

            setTimeout(() => {
                window.location = '/search';
            }, 1500);
        }
    }

    /**
     * Function On Click Button Form Send Sale
     * @param {event} e 
     */
    onClickButtonSelectProduct = (e, product) => {
        e.preventDefault();
        this.setState({ progressBar: true });

        setTimeout(() => {
            this.setState({
                progressBar: false,
                productName: product.name,
                productPrice: product.price,
                campaignId: product.id,
                displayTableInsuranceSale: false,
                displayFormConfirmSale: true
            });
        }, 1500);
    }

    /**
     * Function On Click Button Back Confirm Sale Product
     * @param {event} e 
     */
    onClickButtonBackConfirmSaleProduct = (e) => {
        e.preventDefault();
        this.setState({ progressBar: true });

        setTimeout(() => {
            this.setState({
                progressBar: false,
                displayTableInsuranceSale: true,
                displayFormConfirmSale: false
            });
        }, 1500);
    }

    /**
     * Function On Click Button Confirm Sale Product
     * @param {event} e 
     */
    async onClickButtonConfirmSaleProduct(e) {
        e.preventDefault();

        const {
            userId,
            protocol,
            callcenterUserId,
            partner,
            campaignId,
            identifier,
            fullName,
            birthDate,
            msisdn,
            phone,
            cpf,
            rg,
            email,
            gender,
            maritalStatus,
            cep,
            address,
            number,
            complement,
            neighborhood,
            city,
            state
        } = this.state;

        if (window.confirm("Você realmente deseja confirmar esta venda?")) {

            this.setState({ progressBar: true });

            let body = {
                campaign_id: campaignId,
                identifier: identifier,
                fullname: fullName,
                birth_date: birthDate,
                phone: `55${phone}`,
                cpf: cpf,
                email: email,
                gender: gender,
                rg: rg,
                protocol: protocol,
                callcenter_userid: callcenterUserId,
                partner: partner,
                marital_status: maritalStatus,
                address_complement: complement,
                address_number: number,
                address: {
                    cep: cep,
                    description: address,
                    neighborhood: {
                        description: neighborhood,
                        city: {
                            description: city,
                            uf: {
                                description: state,
                                code: state
                            }
                        }
                    }
                }
            }

            if (birthDate) {
                body.birth_date = moment(birthDate, 'DD/MM/YYYY').format("YYYY-MM-DD");
            }

            if (msisdn) {
                body.msisdn = `55${msisdn}`
            }

            const response1 = await subscribeCallcenter(body);

            if (response1.status === 'info') {
                toastrMessage('warning', response1.message)
                this.setState({ progressBar: false });
                return;
            }

            if (!response1.status) {
                toastrMessage('error', response1.message)
                this.setState({ progressBar: false });
                return;
            }

            const response2 = await sendProtocolCallcenter({
                protocol: protocol,
                user: userId,
                callcenter_userid: callcenterUserId,
                offer: 1234,
                status: 'OPEN',
                action: 'SUBSCRIPTION'
            });

            if (!response2.status) {
                toastrMessage('error', response2.message)
                this.setState({ progressBar: false });
                return;
            }

            setTimeout(() => {
                this.setState({
                    progressBar: false,
                    displayTableSearchClient: false,
                    displayFormConfirmSale: false,
                    displayFormSuccessSale: true
                });
            }, 1500);
        }
    }

    /**
     * Function On Click Button Back Sale Product
     * @param {event} e 
     */
    onClickButtonBackSaleProduct = (e) => {
        window.location = '/search';
        return;
    }

    /**
     * Function On Click Tab Attendance
     */
    async onClickTabAtendance() {

        this.setState({ progressBar: true });

        const response = await getListAttendanceByPhone('phone', validateProtocol('phone'))

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.setState({
            progressBar: false,
            rowsAttendance: response.rows
        });
    }

    /**
     * Function On Click Button Insert Attendance
     */
    onClickButtonInsertAttendance = () => {
        window.location = '/attendance?a=true';
        return;
    }

    /**
     * Function On Click Button Edit Attendance
     * @param {object} record 
     */
    onClickButtonEditAttendance = (record) => {
        window.location = `/attendance?e=${record.id}`;
        return;
    }

    /**
     * Function On Click Button Delete Attendance
     * @param {object} record 
     */
    async onClickDeleteAttendance(record) {
        if (window.confirm('Você deseja excluir esse atendimento?')) {

            this.setState({ progressBar: true });

            const response = await deleteAttendance(record.id);

            if (!response.status) {
                this.setState({ progressBar: false });
                toastrMessage('error', response.message);
                return;
            }

            toastrMessage('success', response.message);
            window.location = '/search';
        }
    }

    /**
     * Function On Blur Search Cep
     * @param {string} cep 
     */
    async onBlurSearchCep(cep) {

        this.setState({
            address: '',
            city: '',
            state: '',
            complement: '',
            neighborhood: ''
        });

        if (cep !== '') {
            const response = await validatorCEP(cep);

            if (!response.erro) {
                this.setState({
                    address: response.logradouro,
                    city: response.localidade,
                    state: response.uf,
                    complement: response.complemento,
                    neighborhood: response.bairro
                });
            }
        }
    }

    /**
     * Function On Click Button Edit User Info
     */
    onClickButtonEditUserInfo() {
        this.setState({
            displayFormEditUserInfo: true,
            displayUserInfo: false
        });
    }

    /**
     * Function On Click Button Back Edit User Info
     */
    onClickButtonBackEditUserInfo() {
        window.location = '/search';
    }

    /**
     * Function Handle Change Form Register Client
     * @param {event} e 
     */
    handleChangeFormEditUserInfo(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Function Handle Submit Form Register Client
     * @param {event} e 
     */
    async handleSubmitFormEditUserInfo(e) {
        e.preventDefault();

        const {
            partner,
            userId,
            fullName,
            birthDate,
            msisdn,
            phone,
            cpf,
            rg,
            email,
            gender,
            maritalStatus,
            cep,
            address,
            number,
            complement,
            neighborhood,
            city,
            state
        } = this.state;

        const validator1 = validatorPhoneNumber(msisdn);

        if (!validator1.status) {
            toastrMessage('error', validator1.message)
            this.setState({ progressBar: false });
            return;
        }

        const validator2 = validatorLandline(phone);

        if (!validator2.status) {
            toastrMessage('error', validator2.message)
            this.setState({ progressBar: false });
            return;
        }

        const validator3 = validatorDate(birthDate);

        if (!validator3.status) {
            toastrMessage('error', validator3.message)
            this.setState({ progressBar: false });
            return;
        }

        const validator4 = validatorCPF(cpf);

        if (!validator4.status) {
            toastrMessage('error', validator4.message)
            this.setState({ progressBar: false });
            return;
        }

        this.setState({ progressBar: true });

        const response = await sendEditUserInfoCallcenter({
            id: userId,
            fullname: fullName,
            birth_date: validator3.date,
            phone: `55${validator2.landline}`,
            msisdn: `55${validator1.msisdn}`,
            cpf: cpf,
            email: email,
            gender: gender,
            partner: partner,
            rg: rg,
            marital_status: maritalStatus,
            address_complement: complement,
            address_number: number,
            address: {
                cep: cep,
                description: address,
                neighborhood: {
                    description: neighborhood,
                    city: {
                        description: city,
                        uf: {
                            description: state,
                            code: state
                        }
                    }
                }
            }
        });

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        toastrMessage('success', response.message);

        setTimeout(() => {
            window.location = '/search';
        }, 1500);
    }

    onClickButtonHistoryPayment(record) {

        this.setState({ progressBar: true });

        setTimeout(() => {
            this.setState({
                progressBar: false,
                displayFormPayment: true,
                rowsPayment: record.billingOrder
            });
        }, 1500);
    }

    /**
     * Render Component
     */
    render() {

        const {
            system,
            page,
            description,
            title,
            progressBar,
            statusFlashMessage,
            flashMessage,
            displayFormSearch,
            displayTableSearchClient,
            displayNavbarTabsInsuranceSearch,
            displayTableInsuranceSale,
            displayFormConfirmSale,
            displayFormSuccessSale,
            displayNavbarTabSearchClient,
            displayNavbarTabAttendance,
            displayNavbarTabSaleProduct,
            displayFormEditUserInfo,
            displayFormPayment,
            buttonSubscriptionProduct,
            buttonHistoryProduct,
            displayUserInfo,
            buttonHistoryPayment,
            type,
            search,
            maskInput,
            placeholderInput,
            disabledInput,
            columnsUsers,
            columnsProtocol,
            columnsAttendance,
            columnsPayment,
            rowsProtocol,
            rowsAttendance,
            rowsProducts,
            rowsUsers,
            fullName,
            birthDate,
            msisdn,
            phone,
            cpf,
            rg,
            email,
            gender,
            maritalStatus,
            cep,
            address,
            number,
            complement,
            neighborhood,
            city,
            state,
            productName,
            productPrice,
            protocolStatus,
            protocol,
            dateProtocol,
            rowsPayment
        } = this.state;

        return (
            <Layout
                system={system}
                page={page}
                description={description}
                progressBar={progressBar}
                protocolStatus={protocolStatus}
                fullName={fullName}
                protocol={protocol}
                dateProtocol={dateProtocol}
            >
                <FlashMessage
                    statusFlashMessage={statusFlashMessage}
                    flashMessage={flashMessage}
                />

                <Content title={title}>
                    {displayFormSearch &&
                        <FormInsuranceSendSearch
                            page={page}
                            type={type}
                            search={search}
                            disabledInput={disabledInput}
                            maskInput={maskInput}
                            placeholderInput={placeholderInput}
                            handleChangeTypeSearch={this.handleChangeTypeSearch}
                            handleChange={e => this.handleChangeFormSendSearch(e)}
                            handleSubmit={e => this.handleSubmitFormSendSearch(e)}
                        />
                    }

                    {displayTableSearchClient &&
                        <TableInsuranceSaleSearchClient
                            rows={rowsUsers}
                            columnsUsers={columnsUsers}
                            permissions={page}
                            buttonSubscriptionProduct={buttonSubscriptionProduct}
                            buttonHistoryProduct={buttonHistoryProduct}
                            onClickButtonHistoryProduct={this.onClickButtonHistoryProduct}
                        />
                    }

                    {displayNavbarTabsInsuranceSearch &&
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="tabs-container">
                                        <ul className="nav nav-tabs">
                                            <li className={displayNavbarTabSearchClient ? 'active' : ''}>
                                                <a className={(displayNavbarTabSearchClient) ? 'nav-link active' : 'nav-link'}
                                                    data-toggle="tab"
                                                    href="#dados-cliente">DADOS DO CLIENTE
                                                </a>
                                            </li>
                                            <li className={displayNavbarTabAttendance ? 'active' : ''}>
                                                <a className={(displayNavbarTabAttendance) ? 'nav-link active' : 'nav-link'}
                                                    data-toggle="tab"
                                                    href="#atendimentos-cliente"
                                                    onClick={e => this.onClickTabAtendance(e)}>ATENDIMENTOS
                                                </a>
                                            </li>
                                            <li>
                                                <a className="nav-link"
                                                    data-toggle="tab"
                                                    href="#protocolos-cliente">PROTOCOLOS DE ATENDIMENTOS
                                                </a>
                                            </li>

                                            {/* {validatorPermissions(page).has('create') &&
                                                <li className={displayNavbarTabSaleProduct ? 'active' : ''}>
                                                    <a className={(displayNavbarTabSaleProduct) ? 'nav-link active' : 'nav-link'}
                                                        data-toggle="tab"
                                                        href="#comprar-produto"
                                                        style={{ background: '#ed5565', color: '#ffffff' }}>COMPRAR PRODUTO
                                                    </a>
                                                </li>
                                            } */}

                                        </ul>
                                        <div className="tab-content">
                                            <div role="tabpanel" id="dados-cliente" className={(displayNavbarTabSearchClient) ? 'tab-pane active' : 'tab-pane'}>
                                                <div className="panel-body">
                                                    {displayUserInfo &&
                                                        <div className="ibox-content" style={{ borderStyle: 'none' }}>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <strong>Nome:</strong> {fullName} <br />
                                                                    <strong>CPF:</strong> {cpf} <br />
                                                                    <strong>Endereço:</strong> {address} {number} <br />
                                                                    <strong>Bairro:</strong> {neighborhood} <br />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <strong>E-mail:</strong> {email} <br />
                                                                    <strong>Telefone:</strong> {phone} <br />
                                                                    <strong>Cidade/UF:</strong> {city} / {state} <br />
                                                                    <strong>CEP:</strong> {cep} <br />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    {validatorPermissions(page).has('edit') &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-w-m btn-primary"
                                                                            style={{ marginTop: 15 }}
                                                                            onClick={e => this.onClickButtonEditUserInfo(e)}
                                                                        >
                                                                            <i className="fa fa-cogs" /> Editar dados do cliente</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="hr-line-dashed"></div>
                                                            <div style={{ marginBottom: 35 }}>
                                                                <TableInsuranceHistoryClient
                                                                    rows={rowsProducts}
                                                                    permissions={page}
                                                                    buttonHistoryPayment={buttonHistoryPayment}
                                                                    onClickButtonUnSubscription={e => this.onClickButtonUnSubscription(e)}
                                                                    onClickButtonHistoryPayment={e => this.onClickButtonHistoryPayment(e)}
                                                                />
                                                            </div>
                                                            <div className="hr-line-dashed"></div>
                                                            <div style={{ marginBottom: 35 }}>
                                                                {displayFormPayment &&
                                                                    <>
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            style={{ float: 'right', marginTop: 10 }}
                                                                            onClick={() => this.setState({ displayFormPayment: false })}
                                                                        ><i className="fa fa-close" /></button>
                                                                        <div className="hr-line-dashed"></div>
                                                                        <h3>Histórico de Pagamentos: </h3>
                                                                        <div className="hr-line-dashed"></div>
                                                                        <Datatables
                                                                            loading={progressBar}
                                                                            columns={columnsPayment}
                                                                            rows={rowsPayment}
                                                                        />
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    {displayFormEditUserInfo &&
                                                        <FormInsuranceSendEditUserInfo
                                                            fullName={fullName}
                                                            msisdn={msisdn}
                                                            phone={phone}
                                                            cpf={cpf}
                                                            rg={rg}
                                                            email={email}
                                                            birthDate={birthDate}
                                                            gender={gender}
                                                            maritalStatus={maritalStatus}
                                                            cep={cep}
                                                            address={address}
                                                            number={number}
                                                            complement={complement}
                                                            neighborhood={neighborhood}
                                                            city={city}
                                                            state={state}
                                                            onBlurSearchCep={this.onBlurSearchCep}
                                                            onClickButtonBackSendSale={e => this.onClickButtonBackEditUserInfo(e)}
                                                            handleChange={e => this.handleChangeFormEditUserInfo(e)}
                                                            handleSubmit={e => this.handleSubmitFormEditUserInfo(e)}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div role="tabpanel" id="atendimentos-cliente" className="tab-pane">
                                                <div className="panel-body">
                                                    <div className="ibox">
                                                        <Datatables
                                                            loading={progressBar}
                                                            columns={columnsAttendance}
                                                            rows={rowsAttendance}
                                                            permissions={page}
                                                            insertRecord={e => this.onClickButtonInsertAttendance(e)}
                                                            editRecord={e => this.onClickButtonEditAttendance(e)}
                                                            deleteRecord={e => this.onClickDeleteAttendance(e)}
                                                            onClickButtonEditAttendance={this.onClickButtonEditAttendance}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div role="tabpanel" id="protocolos-cliente" className="tab-pane">
                                                <div className="panel-body">
                                                    <div className="ibox">
                                                        <Datatables
                                                            loading={progressBar}
                                                            columns={columnsProtocol}
                                                            rows={rowsProtocol}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div role="tabpanel" id="comprar-produto" className={(displayNavbarTabSaleProduct) ? 'tab-pane active' : 'tab-pane'}>
                                                <div className="panel-body">
                                                    <div className="ibox">
                                                        {displayTableInsuranceSale &&
                                                            <TableInsuranceSale
                                                                onClickButtonSelectProduct={this.onClickButtonSelectProduct}
                                                            />
                                                        }
                                                        {displayFormConfirmSale &&
                                                            <FormInsuranceConfirmSale
                                                                productName={productName}
                                                                productPrice={productPrice}
                                                                onClickButtonBackConfirmSaleProduct={this.onClickButtonBackConfirmSaleProduct}
                                                                onClickButtonConfirmSaleProduct={this.onClickButtonConfirmSaleProduct}
                                                            />
                                                        }
                                                        {displayFormSuccessSale &&
                                                            <FormInsuranceSuccessSale
                                                                productName={productName}
                                                                onClickButtonBackSaleProduct={this.onClickButtonBackSaleProduct}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Content>
            </Layout>
        );
    }
}