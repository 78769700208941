import React, { Component } from 'react'
import Loader from '../../components/Loader'
import FormLogin from '../../components/Forms/Login'
import IP from 'ip'

import {
    createToken
} from '../../utils'

import {
    authorization
} from '../../services'

export default class Login extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();
        this.state = {
            loader: false,
            statusFlashMessage: '',
            flashMessage: '',
            username: '',
            password: '',
            address: ''
        }

        this.baseState = this.state;
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    /**
     * Component Did Mount
     */
    componentDidMount(){
        const address = IP.address();
        this.setState({
            address: address
        });
    }

    /**
    * Handle Change Form Send Username and Password
    * @param {event} e 
    */
    handleChangeForm(e) {
        this.setState({
            statusFlashMessage: '',
            flashMessage: '',
            [e.target.name]: e.target.value
        });
    }

    /**
    * Handle Submit Form Send Username and Password
    * @param {event} e 
    */
    async handleSubmitForm(e) {
        e.preventDefault();

        const {
            username,
            password,
            address
        } = this.state;

        this.setState({ loader: true });

        const response1 = await authorization({
            username: username,
            password: password,
            address: address,
            identify: "callcenter-tim"
        });

        if (!response1.status) {
            this.setState({
                loader: false,
                statusFlashMessage: true,
                flashMessage: response1.message
            });
            return;
        }

        createToken(response1.token);

        setTimeout(async () => {
            window.location = `/dashboard`;
        }, 3000);
    }

    /**
     * Render Component
     */
    render() {
        const {
            username,
            password,
            loader,
            statusFlashMessage,
            flashMessage
        } = this.state;

        return (
            <>
                <Loader display={loader} />
                <div className="loginColumns animated fadeInDown">
                    <div className="row">
                        <div className="col-md-6 m-b text-center">
                            <img src="/assets/images/logo-3.png" alt="" />
                            <h2 className="font-bold">MAPFRE TIM Seguros</h2>
                        </div>
                        <div className="col-md-6">
                            <div className="ibox-content">
                                <FormLogin
                                    username={username}
                                    password={password}
                                    statusFlashMessage={statusFlashMessage}
                                    flashMessage={flashMessage}
                                    handleChange={this.handleChangeForm}
                                    handleSubmit={this.handleSubmitForm}
                                    onCloseErrorMessage={this.onCloseErrorMessage}                                
          Segurada                      />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <small>FS © 2020</small>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}