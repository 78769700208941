import moment from 'moment'

/**
 * Function Validator Date
 * @param {int} value 
 */
export function validatorDate(value) {
    let date = moment(value, "DD/MM/YYYY")

    if (!date.isValid()) {
        return { status: false, message: 'Data incorreta, verifique a data digitada e tente novamente!' };
    }

    return { status: true, date: date.format("YYYY-MM-DD") };
}