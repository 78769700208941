import axios from 'axios'

import {
    validateToken
} from '../../../utils'

import {
    URL_BACKEND_OPERATION
} from '../../../constants'

const HEADERS = {
    'auth-jwt': `Bearer ${validateToken('token')}`
}

/**
 * Function Get List All Users
 */
export async function getListAllUsers(){
    try {
        const response = await axios.get(`${URL_BACKEND_OPERATION}/callcenter/user/`, { headers: HEADERS });
        return { status: true, rows: response.data };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}

/**
 * Function Get List by Users
 */
export async function getListUsers(queryString){
    try {
        const response = await axios.get(`${URL_BACKEND_OPERATION}/callcenter/user/list?${queryString}`, { headers: HEADERS });
        return { status: true, rows: response.data.data, totalRecord: response.data.paginate.total };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}

/**
 * Function Insert Users
 */
export async function insertUsers(body){
    try {
        await axios.post(`${URL_BACKEND_OPERATION}/callcenter/user/`, body, { headers: HEADERS });
        return { status: true, message: 'Usuário inserido com sucesso!' };
    } catch (error) { 

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}

/**
 * Function Update Users
 */
export async function updateUsers(body){
    try {
        await axios.put(`${URL_BACKEND_OPERATION}/callcenter/user/`, body, { headers: HEADERS });
        return { status: true, message: 'Usuário alterado com sucesso!' };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}

/**
 * Function Delete Users
 */
export async function deleteUsers(id){
    try {
        await axios.delete(`${URL_BACKEND_OPERATION}/callcenter/user/${id}`, { headers: HEADERS });
        return { status: true, message: 'Usuário removido com sucesso!' };
    } catch (error) {

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        }    
        
        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro!` };
        } 
       
        return { status: false, message: `Ops, ocorreu um erro!` };        
    }
}