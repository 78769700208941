import React from 'react'
import TableInsuranceSaleHomeInsurance from './HomeInsurance'
import TableInsuranceSaleProtectedAccount from './ProtectedAccount'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="tabs-container">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="active"><a className="nav-link active" data-toggle="tab" href="#conta-protegida" style={{ color: '#007ACC', fontWeight: 'bold' }}> CONTA PROTEGIDA</a></li>
                    <li><a className="nav-link" data-toggle="tab" href="#seguro-residencial" style={{ color: '#007ACC', fontWeight: 'bold' }}>PROTEÇÃO RESIDENCIAL</a></li>
                </ul>
                <div className="tab-content">
                    <div role="tabpanel" id="conta-protegida" className="tab-pane active">
                        <div className="panel-body">
                            <TableInsuranceSaleProtectedAccount 
                                onClickButtonSelectProduct={(e, product) => props.onClickButtonSelectProduct(e, product)}                                 
                            />
                            <hr />
                            <h2 style={{ color: '#007ACC', fontWeight: 'bold', marginTop: 10 }}>O que é o seguro:</h2>
                            <ul style={{ fontSize: 15 }}>
                                <li>
                                    <b>O que é o seguro Conta Protegida:</b> é um seguro que garante o auxílio no pagamento
                                    de sua conta telefônica em casos de Desemprego Involuntário ou Incapacidade Física Temporária
                                    por Acidente (para profissionais liberais e autônomos), no valor de R$ 280,00.
                                    Em caso de Morte ou Invalidez Permanente Total por Acidente do segurado, o Conta Protegida garante
                                    o pagamento de uma indenização, conforme o plano contratado.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div role="tabpanel" id="seguro-residencial" className="tab-pane">
                        <div className="panel-body">
                            <TableInsuranceSaleHomeInsurance 
                                onClickButtonSelectProduct={(e, product) => props.onClickButtonSelectProduct(e, product)}                      
                            />
                            <hr />
                            <h2 style={{ color: '#007ACC', fontWeight: 'bold', marginTop: 10 }}>O que é o seguro:</h2>
                            <ul style={{ fontSize: 15 }}>
                                <li>
                                    <b>O que é seguro Proteção Residencial:</b> é um seguro que protege seu patrimônio - casa ou apartamento
                                    - garantindo tranquilidade a você e sua família. E ainda, pode contar com serviços adicionais a residência segurada - serviços emergenciais e não-emergenciais.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}