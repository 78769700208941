import React from 'react'

export default (props) => {
    return (
        <div className="progress progress-striped active" style={{ display: `${(props.display) ? 'block' : 'none'}` }}>
            <div style={{ width: '100%' }} aria-valuemax={100} aria-valuemin={0} aria-valuenow={100} role="progressbar" className="progress-bar progress-bar-primary">
                <span className="sr-only">40% Complete (success)</span>
            </div>
        </div>
    )
}