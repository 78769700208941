import React, { Component } from 'react'
import Layout from '../../layout'

export default class Dashboard extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            system: 'dashboard',
            page: 'dashboard',            
            description: 'Central de Serviços :: MAPFRE TIM Seguros',
            title: 'Dashboard'
        }
    }

    /**
     * Render Component
     */
    render() {
        const {
            system,
            page,
            description,
            title
        } = this.state;

        return (
            <Layout 
                system={system}
                page={page}
                description={description}
                title={title}
            >
                <div className="wrapper wrapper-content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5><i className="fa fa-pie-chart" /> Dashboard</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}