import toastr from 'toastr'

/**
 * Settings
 */
toastr.options = {
    closeButton: true,
    preventDuplicates: true,
    progressBar: true,
    closeDuration: 800
}

/**
 * Function Toastr Message
 * @param {string} status 
 * @param {string} message 
 */
export function toastrMessage(status, message) {
    return toastr[status](message);
}