import React from 'react'
import { validatorPermissions } from '../../../../utils'

export default (props) => {
    return (
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>LINHA SEGURADA</th>
                    <th>PRODUTO</th>
                    <th>VALOR</th>
                    <th>DATA DA ADESÃO</th>
                    <th>INICIO DA VIGENCIA</th>
                    <th>FIM DA VIGENCIA</th>
                    <th>NUMERO DA SORTE</th>
                    <th>STATUS</th>
                    <th>AÇÕES</th>
                </tr>
            </thead>
            <tbody>
                {props.rows.map((response, key) => {
                    return <tr key={key}>
                        <td>{response.phone}</td>
                        <td>{response.name}</td>
                        <td>{response.value}</td>
                        <td>{response.createdAt}</td>
                        <td>{response.validityStart}</td>
                        <td>{response.validityEnd}</td>
                        <td>{response.luckNumber}</td>
                        <td>{response.statusSubscription}</td>
                        <td>

                            {response.statusSubscription === 'ATIVO' && validatorPermissions(props.permissions).has('list') &&
                                <button
                                    className="btn btn-info btn-sm"
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => props.onClickButtonHistoryPayment(response)}>
                                    <i className="fa fa-dollar"></i>
                                </button>
                            }

                            {response.statusSubscription === 'ATIVO' && validatorPermissions(props.permissions).has('delete') &&
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => props.onClickButtonUnSubscription(response)}>
                                    <i className="fa fa-close"></i>
                                </button>
                            }
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    )
}