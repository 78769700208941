import React from 'react'
import { PRODUCT_PROTECTED_ACCOUNT } from '../../../../../constants'

import {
    validatorPermissions
} from '../../../../../utils'

export default (props) => {

    const products = PRODUCT_PROTECTED_ACCOUNT;

    /**
     * Function Create Buttons Table
     */

    const buttons = [];

    products.forEach((obj, key) => {
        buttons.push(<td key={key}>
            <button 
                type="button" 
                className="btn btn-info" 
                onClick={(e) => props.onClickButtonSelectProduct(e, obj)}><i className="fa fa-lock" /> Vender
            </button>
        </td>);
    })

    return (
        <div className="table-responsive">
            <table className="table" cellPadding={4} cellSpacing={2} width="100%" style={{ borderSpacing: 2, borderCollapse: 'separate', fontSize: 14, textAlign: 'center' }}>
                <tbody>
                    <tr style={{ backgroundColor: '#dedede', color: '#666666' }}>
                        <td align="left"><b>COBERTURA</b></td>
                        <td><b>Plano 1</b></td>
                        <td><b>Plano 2</b></td>
                        <td><b>Plano 3</b></td>
                        <td><b>Plano 4</b></td>
                        <td><b>Plano 5</b></td>
                    </tr>
                    <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
                        <td align="left" rel="popover" data-title="<b>Morte</b>" data-content="Cobertura que garante (1)um crédito único no valor conforme o plano contratado, para a sua família pagar as contas de telefone,  em caso de morte do segurado em decorrência de doença ou acidente, durante a vigência do seguro." data-original-title><i className="fa fa-question-circle" /> Morte</td>
                        <td>R$ 840,00</td>
                        <td>R$ 1.500,00</td>
                        <td>R$ 840,00</td>
                        <td>R$ 2.000,00</td>
                        <td>R$ 2.500,00</td>
                    </tr>
                    <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
                        <td align="left" rel="popover" data-title="<b>Invalidez Permanente Total por Acidente</b>" data-content="Cobertura que garante (1) um crédito único conforme o plano contratado, para pagar suas  contas de telefone, ao próprio segurado, caso este venha a se tornar total e permanentemente inválido em decorrência direta e exclusiva de acidente coberto durante a vigência do seguro." data-original-title><i className="fa fa-question-circle" /> Invalidez Permanente Total por Acidente</td>
                        <td>R$ 840,00</td>
                        <td>R$ 1.500,00</td>
                        <td>R$ 840,00</td>
                        <td>R$ 2.000,00</td>
                        <td>R$ 2.500,00</td>
                    </tr>
                    <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
                        <td align="left" rel="popover" data-title="<b>Desemprego Involuntário</b>" data-content="Cobertura exclusiva para profissionais que comprovem registro em carteira com o mesmo empregador pelo período mínimo de 12 meses contínuos, que garante um credito único no valor de R$ 280,00, para pagar suas contas de telefone, em caso desemprego involuntário e sem justa causa do segurado.<br/>Carência: 31 dias.<br/>Franquia: 31 dias." data-original-title>
                            <i className="fa fa-question-circle" /> Desemprego Involuntário
                        </td>
                        <td>R$ 280,00</td>
                        <td>R$ 280,00</td>
                        <td>R$ 280,00</td>
                        <td>R$ 280,00</td>
                        <td>R$ 280,00</td>
                    </tr>
                    <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
                        <td align="left" rel="popover" data-title="<b>Incapacidade Física Temporária por Acidente</b>" data-content="Cobertura exclusiva para o segurado que comprove sua renda como profissional liberal e autônomo, que garante um credito único no valor de R$ 280,00, para pagar suas contas de telefone, em caso de incapacidade física temporária por acidente, que impeça o segurado de realizar sua atividade remunerativa habitual, sempre que este estado seja continuo por um período superior a 15 dias.<br/>Carência: 0 dias.<br/>Franquia: 15 dias." data-original-title><i className="fa fa-question-circle" /> Incapacidade Física Temporária por Acidente</td>
                        <td>R$ 280,00</td>
                        <td>R$ 280,00</td>
                        <td>R$ 280,00</td>
                        <td>R$ 280,00</td>
                        <td>R$ 280,00</td>
                    </tr>
                    <tr style={{ backgroundColor: '#dedede', color: '#666666' }}>
                        <td align="left"><b>BENEFÍCIOS</b></td>
                        <td><b /></td>
                        <td><b /></td>
                        <td><b /></td>
                        <td><b /></td>
                        <td><b /></td>
                    </tr>
                    <tr style={{ backgroundColor: '#ededed', color: '#666666' }}>
                        <td align="left" rel="popover" data-title="<b>Assistência a recolocação profissional</b>" data-content="Para momentos que antecedem a conquista de um novo trabalho, um serviço sob medida, para auxiliar o segurado na busca de um novo emprego." data-original-title>
                            <i className="fa fa-question-circle" /> Assistência a recolocação profissional
                        </td>
                        <td>Não</td>
                        <td>Não</td>
                        <td>Não</td>
                        <td>Sim</td>
                        <td>Sim</td>
                    </tr>
                    <tr style={{ backgroundColor: '#dedede', color: '#666666' }}>
                        <td align="left"><b>PRÊMIO MENSAL - MENSALIDADE</b></td>
                        <td><b>R$ 5,90</b></td>
                        <td><b>R$ 7,99</b></td>
                        <td><b>R$ 9,99</b></td>
                        <td><b>R$ 13,99</b></td>
                        <td><b>R$ 17,99</b></td>
                    </tr>
                    <tr style={{ color: '#FF3333', fontSize: 18 }}>
                        <td align="left" />
                        { validatorPermissions('salle').has('create') &&
                            buttons
                        }
                    </tr>
                </tbody>
            </table>
        </div>
    )
}