import React, { Component, Fragment } from 'react'
import ReactDatatable from '@ashvin27/react-datatable'

import {
    validatorPermissions
} from '../../utils'

export default class Datatables extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state = {
            columns: [],
            records: [],
            loading: false,
            config: {
                page_size: 10,
                show_filter: this.props.filter ? true : false,
                show_pagination: true,
                pagination: 'advance',
                length_menu: [10, 20, 50, 100],
                button: {
                    excel: false,
                    print: false
                },
                language: {
                    length_menu: "Mostrar _MENU_ registros por página",
                    filter: "Pesquisar",
                    info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
                    pagination: {
                        first: "Primero",
                        previous: "Anterior",
                        next: "Próximo",
                        last: "Último"
                    },
                    no_data_text: "Nenhum registro encontrado",
                    loading_text: "Estamos carregando os dados"
                }
            }
        }
        this.baseState = this.state;
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.getColumns(this.props.columns);
    }

    /**
     * Component Will Unmount
     */
    componentWillUnmount() {
        this.setState(this.baseState)
    }

    /**
     * Function Get Columns
     * @param {object} rows 
     */
    getColumns(rows) {
        
        let data = [];
        let buttons = {
            key: "acoes",
            text: "AÇÕES",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        { validatorPermissions(this.props.permissions).has('edit') &&
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.props.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                        }

                        { validatorPermissions(this.props.permissions).has('delete') &&
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.props.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        }
                    </Fragment>
                );
            }
        }

        for (var key in rows) {
            if (rows.hasOwnProperty(key)) {
                data.push({
                    key: key,
                    text: rows[key],
                    align: "left",
                    sortable: false,
                });
            }
        }

        data.push(buttons);

        this.setState({
            columns: data
        });
    }

    /**
     * Render Component
     */
    render() {

        const {
            config,
            columns
        } = this.state;

        return (
            <>

                {validatorPermissions(this.props.permissions).has('create') ?
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.props.insertRecord(true)}
                        style={{ marginBottom: 20 }}>
                        <i className="fa fa-plus" /> Adicionar Novo Registro
                    </button>
                    : ''
                }

                <ReactDatatable
                    config={config}
                    columns={columns}
                    records={this.props.rows}
                    loading={this.props.loading}
                    dynamic={this.props.dynamic}
                    total_record={this.props.totalRecord}
                    onChange={this.props.onChange}
                />

            </>
        );
    }
}