import React from 'react'
import { PropTypes } from 'prop-types'

const Content = (props) => {
    return <div className="row">
        <div className="col-lg-12">
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>{props.title}</h5>
                </div>
                <div className="ibox-content">
                    {props.children}
                </div>
            </div>
        </div>
    </div>
}

Content.propTypes = {
    title: PropTypes.string.isRequired
};

export default Content;