import React, { Component } from 'react'
import Layout from '../../../layout'
import Content from '../../../components/Content'
import Datatables from '../../../components/Datatables'
import FormSubGroup from '../../../components/Forms/Settings/SubGroup'

import {
    getListAllGroup,
    getListSubGroup,
    insertSubGroup,
    updateSubGroup,
    deleteSubGroup
} from '../../../services'

import {
    validateToken,
    toastrMessage
} from '../../../utils'

export default class SubGroup extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            system: 'settings',
            page: 'subgroup',
            description: 'Configurações :: Sub-Grupos',
            title: 'Listagem dos Sub-Grupos',
            progressBar: false,
            displayList: true,
            displayForm: false,
            displayFormUpdate: false,
            action: '',
            name: '',
            groupId: '',
            parentId: '',
            subGroupId: '',
            columns: {
                id: '#',
                name: 'SUBGRUPO',
                group_name: 'GRUPO'
            },
            rowsSubGroup: [],
            rowsGroup: [],
            totalRecord: 0
        }

        this.baseState = this.state;
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.getListAllGroup();
        this.getListSubGroup();
        this.validateToken();
    }

    /**
     * Component Will UnMount
     */
    componentWillUnmount() {
        this.setState(this.baseState);
    }

    /**
     * On Click Return Page
     */
    onClickReturnPage = () => {
        this.setState(this.baseState);
        this.getListAllGroup();
        this.getListSubGroup();
        this.validateToken();
    }

    /**
     * Function Insert Record
     */
    onClickInsertRecord = () => {
        this.setState({
            action: 'insert',
            title: 'Inserir Registro',
            displayList: false,
            displayForm: true
        });
    }

    /**
     * Function Edit Record
     */
    onClickEditRecord = (record) => {
        this.setState({
            action: 'edit',
            title: 'Editar Registro',
            displayList: false,
            displayForm: true,
            name: record.name,
            groupId: record.group_id,
            subGroupId: record.id
        });
    }

    /**
     * Function Validate Token
     */
    validateToken() {

        const response = validateToken('decode_token');

        if (!response) {
            this.setState(this.baseState);
            return;
        }

        const parentId = (response.profile.sub_group.parent_id === null) ?
            response.profile.sub_group.id :
            response.profile.sub_group.parent_id;

        this.setState({
            parentId: parseInt(parentId)
        });
    }
    
    /**
     * Function get List SubGroup
     */
    async getListSubGroup(queryString = "page=1&limit=10") {

        this.setState({ progressBar: true });

        const response = await getListSubGroup(queryString);

        if (!response.status) {
            this.setState(this.baseState);
            return;
        }

        this.setState({
            loader: false,
            progressBar: false,
            displayForm: false,
            displayList: true,
            rowsSubGroup: response.rows,
            totalRecord: response.totalRecord
        });
    }

    /**
     * Function Table Change Handler
     * @param {object} data 
     */
    tableChangeHandler = (data) => {

        let queryString = Object.keys(data).map((key) => {

            switch (key) {
                case 'page_number':
                    return encodeURIComponent('page') + '=' + encodeURIComponent(data[key]);
                case 'page_size':
                    return encodeURIComponent('limit') + '=' + encodeURIComponent(data[key]);
                case 'sort_order':
                    return data[key] ? encodeURIComponent('sort') + '=' + encodeURIComponent(data[key].order) : '';
                default:
                    return '';
            }

        }).filter(value => !!value).join('&');
        this.getListSubGroup(queryString);
    }

    /**
     * Function Get List Group
     */
    async getListAllGroup() {

        this.setState({ progressBar: true });

        const response = await getListAllGroup();

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.setState({
            progressBar: false,
            rowsGroup: response.rows
        });
    }

    /**
     * Function Insert SubGroup
     */
    async insertSubGroup() {

        const {
            name,
            parentId,
            groupId
        } = this.state;

        this.setState({ progressBar: true });

        const response = await insertSubGroup({
            name: name,
            parent_id: parseInt(parentId),
            group: parseInt(groupId)
        });

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.getListSubGroup();
        toastrMessage('success', response.message);
    }

    /**
     * Function Insert SubGroup
     */
    async updateSubGroup() {

        const {
            subGroupId,
            name,
            parentId,
            groupId
        } = this.state;

        this.setState({ progressBar: true });

        const response = await updateSubGroup({
            id: subGroupId,
            name: name,
            parent_id: parseInt(parentId),
            group: parseInt(groupId)
        });

        if (!response.status) {
            toastrMessage('error', response.message)
            this.setState({ progressBar: false });
            return;
        }

        this.getListSubGroup();
        toastrMessage('success', response.message);
    }

    /**
     * Function Delete SubGroup
     */
    async delete(record) {
        if(window.confirm('Você deseja excluir esse registro?')){

            this.setState({ progressBar: true });

            const response = await deleteSubGroup(record.id);
    
            if (!response.status) {
                this.setState({ progressBar: false });
                toastrMessage('error', response.message);
                return;
            }
    
            toastrMessage('success', response.message);
            this.getListSubGroup();
        }
    }    

    /**
    * Handle Change Form Send Username and Password
    * @param {event} e 
    */
    handleChangeForm(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
    * Handle Submit Form Send Username and Password
    * @param {event} e 
    */
    async handleSubmitForm(e) {
        e.preventDefault();

        const {
            action
        } = this.state;

        switch (action) {
            case 'edit':
                this.updateSubGroup();
                break;

            default:
                this.insertSubGroup();
                break;
        }
    }

    /**
     * Render Component
     */
    render() {

        const {
            system,
            page,
            description,
            title,
            progressBar,
            name,
            groupId,
            displayList,
            displayForm,
            columns,
            rowsSubGroup,
            rowsGroup,
            totalRecord
        } = this.state;

        return (
            <Layout
                system={system}
                page={page}
                description={description}
                progressBar={progressBar}
            >
                <Content title={title}>
                    {displayList &&
                        <Datatables
                            permissions={page}
                            columns={columns}
                            rows={rowsSubGroup}
                            loading={progressBar}
                            insertRecord={e => this.onClickInsertRecord(e)}
                            editRecord={e => this.onClickEditRecord(e)}
                            deleteRecord={e => this.delete(e)}
                            dynamic={true}
                            totalRecord={totalRecord}
                            onChange={e => this.tableChangeHandler(e)}
                        />
                    }
                    {displayForm &&
                        <FormSubGroup
                            name={name}
                            groupId={groupId}
                            rows={rowsGroup}
                            handleChange={this.handleChangeForm}
                            handleSubmit={this.handleSubmitForm}
                            onClickReturnPage={this.onClickReturnPage}
                        />
                    }
                </Content>
            </Layout>
        );
    }
}