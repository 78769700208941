import { validateToken } from '../../Token'

/**
 * Function Validator Permissions
 */
export function validatorPermissions(page) {

    const token = validateToken();
    let permission = [];
    let pagePermission = (page === 'search')? 'salle' : page;
    
    for (var key in token.permission) {
        if (token.permission[key].uri === pagePermission) {
            if(token.permission[key].crud !== null){
                token.permission[key].crud.forEach(element => {

                    if (element.list === "1") {
                        permission.push('list');
                    }

                    if (element.create === "1") {
                        permission.push('create');
                    }

                    if (element.edit === "1") {
                        permission.push('edit');
                    }

                    if (element.delete === "1") {
                        permission.push('delete');
                    }
                });
            }
        }
    }
    return new Set(permission);
}

/**
 * Function Validator Permissions URI
 */
export function validatorPermissionsURI(uri) {

    const token = validateToken();
    const userPermissionsInfo = token.permission;
    let url = (uri === 'search')? 'salle' : uri;
    let permission = { status: false };

    for (var key in userPermissionsInfo) {
        if (userPermissionsInfo[key].uri === url) {
            if(userPermissionsInfo[key].crud !== null){
                userPermissionsInfo[key].crud.forEach(element => {
                    if (element.list === "1") {
                        permission.status = true;
                    }
                });
            }
        }
    }

    return (permission.status) ? true : false;
}