import React from 'react'
import Loader from '../components/Loader'
import ProgressBar from '../components/ProgressBar'
import Protocol from '../components/Protocol'
import MenuLeft from '../components/Menu/Left'
import MenuTop from '../components/Menu/Top'
import Footer from '../components/Footer'

export default (props) => {
    return (
        <>
            <Loader display={props.loader} />
            <div id="wrapper">
                <MenuLeft {...props} />
                <div id="page-wrapper" className="gray-bg">
                    <MenuTop {...props} />
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <Protocol {...props} />
                        <ProgressBar display={props.progressBar} />
                        {props.children}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}