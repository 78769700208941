import React from 'react'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal" onSubmit={props.handleSubmit}>
                    <div className="form-group">
                            <label className="col-sm-2 control-label">Nome *</label>
                            <div className="col-sm-4">
                                <input
                                    name="name"
                                    value={props.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    placeholder="Nome"
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>                        
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Grupo *</label>
                            <div className="col-sm-4">
                                <select 
                                    className="form-control"
                                    onChange={props.handleChange}
                                    name="groupId"
                                    value={props.groupId}
                                    required
                                >
                                    <option>[Selecione uma opção]</option>
                                    {props.rows.map((response, key) => {
                                        return <option key={key} value={response.id}>{response.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="hr-line-dashed" />
                        <div className="form-group">
                            <div className="col-sm-4 col-sm-offset-2">
                                <button className="btn btn-white" type="button" onClick={() => props.onClickReturnPage(false)}>Voltar</button>
                                <button className="btn btn-primary" type="submit">Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}