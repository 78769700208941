import axios from 'axios'
import moment from 'moment'

import {
    validateToken
} from '../../../utils'

import {
    URL_BACKEND,
    BRAND_NAME,
    REQUESTER_NAME
} from '../../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Brand-Name': BRAND_NAME,
    'X-FS-Requester-Name': REQUESTER_NAME,
    'Authorization': `Bearer ${validateToken('token')}`
}

/**
 * Function Subscribe Callcenter
 * @param {json} body 
 */
export async function subscribeCallcenter(body) {
    try {
        await axios.post(`${URL_BACKEND}/bff/api/v2/insurance/callcenter/subscribe`, body, { headers: HEADERS });
        return { status: true, message: 'Registro inserido com sucesso!', dateProtocol: moment().format("dd/MM/yyyy HH-mm") };
    } catch (error) {

        if (error.response) {

            if(error.response.status === 422){
                return { status: 'info', message: `Ops, atualize os dados do cliente e tente novamente!` };
            }

            if(error.response.status === 401){
                return { status: 'info', message: `Ops, o cliente já possui o produto selecionado!` };
            }            

            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}

/**
 * Function UnSubscribe Callcenter
 * @param {json} body 
 */
export async function unSubscribeCallcenter(body) {
    try {
        await axios.post(`${URL_BACKEND}/bff/api/v2/insurance/callcenter/unsubscribe`, body, { headers: HEADERS });
        return { status: true, message: 'Registro inserido com sucesso!' };
    } catch (error) {

        if (error.response) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        if (error.request) {
            return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
        }

        return { status: false, message: `Ops, ocorreu um erro. Tente novamente!` };
    }
}