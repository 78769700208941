import React from 'react'
import { Link } from 'react-router-dom'
import { validatorPermissionsURI } from '../../../../utils'

export default (props) => {
    return (
        <>
            {validatorPermissionsURI('dashboard') ?
                <li className={(props.system === 'dashboard') ? 'active' : ''}>
                    <Link to="/dashboard">
                        <i className="fa fa-th-large" />
                        <span className="nav-label">Dashboard</span>
                    </Link>
                </li>
                : ''
            }
        </>
    );
}