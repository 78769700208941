import React from 'react'
import { validatorPermissions } from '../../../../../utils'

export default (props) => {
    return (
        <div className="ibox-content">
            <div className="row">
                <div className="col-sm-12">
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Dados do Cliente *</label>
                            <div className="col-sm-10">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>NOME</th>
                                            <th>CPF</th>
                                            <th>TELEFONE</th>
                                            <th>AÇÕES</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {props.rows.map((response, key) => {
                                            return <tr key={key}>
                                                <td>{response.fullName}</td>
                                                <td>{response.cpf}</td>
                                                <td>{response.phone}</td>
                                                <td>

                                                    {props.buttonSubscriptionProduct && validatorPermissions(props.permissions).has('create') &&
                                                        <button
                                                            style={{ marginRight: 10 }}
                                                            className="btn btn-danger btn-sm"
                                                            onClick={(e) => props.onClickButtonHistoryProduct(e, response, 'salle')}>
                                                            <i className="fa fa-plus"></i> comprar</button>
                                                    }

                                                    {props.buttonHistoryProduct && validatorPermissions(props.permissions).has('list') &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-primary"
                                                            onClick={(e) => props.onClickButtonHistoryProduct(e, response, 'history')}>
                                                            <i className="fa fa-search"></i> histórico</button>
                                                    }
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}